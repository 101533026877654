﻿// Help Content Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base help content styles

div {
	&.instructions {
		p {
			margin: 12px 0;
		}

		ul {
			list-style: disc outside;

			li {
				list-style: disc outside;
			}
		}

		ol {
			list-style: decimal outside;

			li {
				list-style: decimal outside;
			}
		}

		ul, ol {
			padding: 10px 20px;
		}
	}

	&.content {
		ul li, ol li {
			margin-bottom: 10px;
		}
	}
}