﻿// Base Footnote Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base footnote styles

// Footnote row and text
.footnote-row {
    margin: 15px 0;
}

.footnote-row-text {
    color: #333333;
    font-size: 11px;
    padding: 10px 0;
}

.footnote-row-text sup {
    color: #4276aa;
    line-height: 9px;
    padding-right: 3px;
    vertical-align: top;
}