﻿// Clean List Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base clean list styles

// Clean list item styling
.clean-list .list-item {
    background-image: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    clear: both;
    color: #707070;
    display: block;
    font-size: 12px;
    line-height: 20px;
    margin: 0 0 -1px 0 !important;
    overflow: hidden;
    padding: 15px !important;
    position: relative;
    text-align: left;
}

.clean-list.list .list-item .row-actions a {
    position: relative;
}

.clean-list .list-item .row-actions a {
    background: none repeat scroll 0 0 #fff;
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    color: #333333;
    font-weight: bold;
    margin-top: -22px;
    padding: 3px 8px;
    right: 15px;
    top: 50%;
}

.clean-list .list-item:last-child {
    margin-bottom: 0;
}