﻿// Base Accordion Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base accordion styles

.accordion-details {
    background-color: #F5F5F5;
    border-bottom: 1px solid #f0f0f0;
    border-top: 1px solid #f0f0f0;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 10px;
    padding: 20px 20px 0;
    position: relative;
}

.hide-text {
    cursor: pointer;
    display: block;
    height: 20px;
    padding: 10px;
    position: relative;
    text-indent: -9999px;
    width: 26px;
}

.accordion-details .hide-text {
    margin: 10px;
    position: absolute;
    right: 0;
    top: 0;
}