﻿// Switch Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base checkbox switch styles

.switch-wrapper {
	&.blue input:checked + label.on-off {
		.switch {
			background-color: #4672AB;
		}

		&:after {
			color: #4672AB;
		}
	}

	input {
		display: none;

		&:checked + label {
			&:before {
				color: $light-gray;
			}

			&:after {
				color: $light-gray;
			}

			&.on-off {
				.switch {
					background-color: #81A36C;
				}

				&:after {
					color: #81A36C;
				}
			}

			.switch:before {
				left: inherit;
				right: 3px;
			}
		}
	}

	label {
		color: $light-gray;
		display: inline-block;
		line-height: 22px;
		vertical-align: bottom;

		&:before {
			content: attr(data-left);
			color: $light-gray;
			display: inline-block;
			vertical-align: middle;
		}

		&:after {
			content: attr(data-right);
			display: inline-block;
			vertical-align: middle;
		}

		&.disabled .switch {
			cursor: default;
		}
	}

	.switch {
		background-color: #B4B4B4;
		border-radius: 15px;
		cursor: pointer;
		display: inline-block;
		margin: 0 10px;
		padding: 11px 20px;
		position: relative;
		transition: 0.10s all linear;
		vertical-align: middle;

		&:before {
			background-color: #ffffff;
			border-radius: 15px;
			content: '';
			display: block;
			height: 16px;
			left: 3px;
			margin-top: -8px;
			position: absolute;
			top: 50%;
			width: 16px;
		}
	}
}