﻿// Misc. Base Styles
// Included in every theme via the _base.scss file
//
// This partial sets up some misc. base styles

// Global table-header style
.table-header {
    color: #333333;
    font-size: 10px;
    text-transform: uppercase;
}

.slide-content .search-form input.button {
	padding: 4px 8px;
}

// Selected
// =============================================================================
table.ui-datepicker-calendar tr td.ui-datepicker-today a.ui-state-highlight.ui-state-default {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.1) 100%, rgba(0, 0, 0, 0.3) 100%);
    color: #FFFFFF !important;
}

.favorite {
	width: 16px;
	display: inline-block;
	height: 16px;
	vertical-align: middle;

	&.disabled {
		color: #D8D8D9;
	}
}

.title-bar,
.filters {
	color: #FFFFFF;
	font-weight: bold;
}

div.filter-slide {
	margin-top: 20px;
}

div.fileStyleWrapper {
  height: auto !important;
  width: auto !important;
}

/***** copied over from dashboard v2 *****/

.more-link {
	display: inline-block;
	padding: 0 20px 0 0;
	position: relative;

	&:after {
		background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png");
		background-position: -53px 7px;
		background-repeat: no-repeat;
		content: " ";
		display: block;
		height: 20px;
		margin-top: -10px;
		position: absolute;
		right: 0;
		top: 50%;
		width: 20px;
	}

	&:hover:after {
		background-position: -53px -13px;
	}
}

#close_banner {
	background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/icon_banner_close.png");
	cursor: pointer;
	height: 20px;
	margin-top: -18px;
	position: absolute;
	right: 0;
	text-indent: -9999px;
	width: 20px;
}

.slide-content {
	background: #FFF;
	border: 1px solid #D0D0D0;
	border-top: none;
	overflow: hidden;
	top: 32px;
	left: 0;
	z-index: 10000;
	width: 867px;
}

.no-list-results {
	color: #707070;
	font-size: 16px;
	line-height: 150%;
	margin: 20px 0;
	padding: 20px 10px;
	text-align: center;
}

.icon-new-window {
	&:after {
		background-position: -101px -360px;
		margin-left: 10px;
		opacity: 0.7;
	}

	&:hover:after {
		opacity: 1;
	}

	padding-right: 30px;
}

.flexcroll-hide-default {
	overflow: hidden !important;
}

.pagination #show-more {
	display: block;
	text-align: center;
	border: 1px solid #D1D2D4;
	padding: 10px 0;

	&.waiting {
		opacity: 0.5;
		filter: alpha(opacity = 50) !important;
		cursor: default;
	}
}

.tip {
	display: none !important;
}

span.img {
	background-color: #FFFFFF;

	img {
		display: block;
	}
}

.exception_stack_trace {
	word-wrap: break-word !important;
}

div.save-search {
	position: absolute;
	right: 0;
	bottom: 10px;
}

// TODO: Belongs in BillPay
.column-legacy.scheduled-info {
	width: 32% !important;
}