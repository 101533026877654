﻿// Base Instructions Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base instructions styles

.instructions {
	min-width: 760px;

	ul {
		padding-left: 20px;
		margin-bottom: 10px;
	}

	#close_help {
		display: none;
	}
}

div.instructions ol {
	padding-left: 20px;
	margin-bottom: 10px;
}

.instructions li, div.instructions p {
	line-height: 1.5;
	text-align: top;
}

.instructions {
	ul li {
		list-style: disc outside;
	}

	ol li {
		list-style: decimal;
	}

	h4 {
		margin: 20px 0 10px;
		font-size: 16px;
		font-weight: bold;
	}

	img {
		&.large {
			width: 99%;
		}

		&.small {
			width: 30%;
			float: right;
			margin: 4px 15px;
			clear: right;
		}

		&.border {
			background-clip: padding-box;
			border-radius: 4px;
			border: 1px solid #D0D0D0;
		}

		width: 55%;
	}
}
