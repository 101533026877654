﻿// Disclosure Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base disclosure styles

div {
	&.disclosure {
		border: 1px solid #B8BABA;
		border-radius: 3px;
		box-shadow: inset 1px 1px 3px #ccc;
		padding: 10px;
		margin: 10px 0 5px;
		height: 270px;
		overflow: auto;

		p {
			margin: 12px 0;
		}

		h2 {
			margin: 5px 0;
			font-size: 1.2em;
			font-weight: bold;
		}

		ul {
			list-style: disc outside;

			li {
				list-style: disc outside;
			}
		}

		ol {
			list-style: decimal outside;

			li {
				list-style: decimal outside;
			}
		}

		ul, ol {
			padding: 10px 20px;
		}
	}

	&.disclosure-options {
		height: 40px;
	}
}

// Disclosure agreements
.disclosure-large {
	height: 500px !important;
}

div.disclosure-short {
	height: auto !important;
}

.site-disclosure-label-width-override {
    width: 80% !important;
}

#email_disclosure {
	margin-top: 5px;
}