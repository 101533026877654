﻿// Modal Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base modal styles

.x-window-plain.x-plain, .x-window-plain.x-window-dlg {
	border-radius: 5px;
}

.x-window-plain.x-plain .x-tool.x-tool-close {
    background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/icon_close.png");
    height: 10px;
    width: 10px;
    line-height: 150%;
}

// General Modal Styles
// =============================================================================
.modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9000;
    display: none; // This will be changed by modal.js
    outline: 0;
    overflow-x: hidden;
    overflow-y: scroll;
}

.modal-subheader {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    padding: 20px 0 10px;
}

.modal-find input[type="text"].modal-search {
    background-color: #FFFFFF;
    background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png");
    background-position: -212px -411px;
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    box-shadow: 0 1px 3px #d9d9d9 inset;
    box-sizing: border-box;
    display: block;
    font-size: 13px;
    height: 34px;
    margin: 0 0 20px;
    padding-left: 30px;
    padding-bottom: 6px;
    padding-top: 7px;
    width: 100%;
}

.modal-find input[type="text"].modal-search:focus {
    background-color: #fff;
    border-color: #91B2D3;
}

.modal-find .clear-input {
    background: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png") repeat scroll -314px -270px transparent;
}

.modal-close {
    background: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png") repeat scroll -96px -153px transparent;
    cursor: pointer;
    height: 25px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 23px;
    z-index: 9014; // One higher than the modal
}

.modal-subline {
    display: block;
    font-size: 11px;
    font-weight: 700;
    padding: 15px 0;
}

.modal-popular-banks {
    overflow: auto;
    padding-bottom: 15px;
}

    .modal-popular-banks li {
        float: left;
        padding: 0 0 15px;
        width: 50%;
    }

    .modal-popular-banks a {
        text-decoration: underline;
    }

.modal-search-results {
    margin: 10px 0 50px;
}

    .modal-search-results li {
        position: relative;
    }

    .modal-search-results a {
        border-top: 1px solid #d5d5d5;
        color: #000;
        display: block;
        font-weight: 700;
        line-height: 140%;
        padding: 15px 40px 15px 10px;
        text-decoration: none;
    }

    .modal-search-results .ai-bank-link {
        color: #396492;
        display: block;
        font-weight: 400;
        text-decoration: underline;
    }

.modal-arrow-right {
    background: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png") repeat scroll 0px -20px transparent;
    display: block;
    height: 14px;
    margin-top: -7px;
    margin-right: 10px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 8px;
}

.modal-arrow-down {
  background: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png") repeat scroll 0px -20px transparent;
  display: inline-block;
  height: 14px;
  transform: rotate(90deg);
  vertical-align: middle;
  margin-left: 7px;
  width: 8px;
}

.modal-load-more {
    border-bottom: 1px solid #d5d5d5;
    position: relative;
    text-align: center;
}

    .modal-load-more a {
        color: #000;
        display: block;
        font-weight: 700;
        padding: 15px 0;
        text-decoration: none;
    }

.modal-footer-container {
    float: right;
    margin-top: 40px;
	a:first-child {
		margin-right: 5px;
	}
}

// Modal Side Navigation
// =============================================================================
div#modal_sidenav ul.list li a.list-item {
	padding: 5px;
}

// Modal Wizard Tabs
// =============================================================================

// Keep these 2 in Base
.modal ul#progress_nav li.ui-state-active + li:not(.ui-state-disabled):before {
	content: '';
	position: absolute;
	left: -70%;
	top: -1px;
	z-index: 1;
	width: 70%;
	display: block;
	height: 2px;
}

.modal ul#progress_nav li.ui-state-active:before {
	content: '';
	position: absolute;
	left: -1px;
	top: -2px;
	z-index: 0;
	width: 1px;
	display: block;
	height: 2px;
}

form div.field {
    margin-bottom: 5px;
}

// Move and customize these for each theme when we have a widget that uses them.
// Unable to test until then.
.modal ul#progress_nav li.ui-state-active {
	border-top-color: #436D8C;
}

.modal ul#progress_nav li span.line, .modal ul#progress_nav li span.step {
	background-color: #436D8C;
	border-color: #436D8C;
}

.modal ul#progress_nav li.ui-state-active + li:not(.ui-state-disabled):before {
	content: '';
	position: absolute;
	left: -70%;
	top: -1px;
	z-index: 1;
	width: 70%;
	display: block;
	height: 2px;
	background: #436D8C;
}

.modal ul#progress_nav li.ui-state-active:before {
	content: '';
	position: absolute;
	left: -1px;
	top: -2px;
	z-index: 0;
	width: 1px;
	display: block;
	height: 2px;
	background: #436D8C;
}

#external_url_modal {
	.modal-body {
		padding: 15px;
	}
}

.external-url-modal {
	.modal-body p {
		color: #707070;
		font-size: 15px;
		text-align: left;
	}
}

// Modal Fields
.modal ul.fields {
    background: none repeat scroll 0 0 #F0F0F0;
    border: 1px solid #dedede;
    border-radius: 5px;
    color: #333333;
    overflow: hidden;
    margin: 20px 0;
    display: block;
}

.modal ul.fields > li {
     margin: 0;
     padding:15px 20px;
     overflow: hidden;
     position: relative;
     border-bottom: 1px solid #dedede;
     .title{
        display:block;
        font-weight: bold;
        margin-bottom: 10px;
    }
}

.modal ul.fields > li:last-child {
    border-bottom: 0 none !important;
}

.modal ul.fields > li label {
    font-size:12px;
    padding-top: 7px !important;
}

.modal ul.fields > li input,
.modal ul.fields > li textarea {
    float: right;
    box-sizing: border-box;
}

.modal ul.fields > li ul.segmented-buttons a.button {
    box-sizing: border-box;
    text-align: center;
    width: 142px;
}

.modal ul.fields > li div p.note-text {
    padding: 15px 0 15px 0;
}

.modal .toggle-buttons li {
    border-bottom: 0 none !important;
    padding: 0 !important;
}

.modal ul.fields .segmented-buttons li {
    border-bottom: 0 none;
    padding: 0;
}

.modal .modal-bottom-button {
    float: right;
    margin-left: 5px;
    margin-top: 20px;
}

.modal .field-link:hover {
    cursor: pointer;
    background-color: #E7E7E7;
}

.modal ul.fields li.field-icon {
    padding-left: 60px;
    position: relative;
}

.modal ul.fields .field-link.field-icon:before {
    display: inline-block;
    margin-left: -44px;
    font-size: 30px;
    position: absolute;
    height: 100%;
    top: 0;
    line-height: 70px;
}

.modal ul.fields .field-link.field-icon:after {
    background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png");
    content: '';
    height: 20px;
    margin-top: 27px;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
}

.modal-open {
    overflow: hidden;
}

.modal-empty {
    padding: 0 !important;
    position: fixed !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90vh;
    max-width: 90vw;
    overflow-y: auto !important;

    .x-plain-tl,
    .x-plain-bl {
        display: none;
    }
}

.modal {
	.x-plain-body {
		padding: 10px 0px 10px 3px;

		.ui-widget-content {
			color: #333333;
		}
	}

	.x-panel-body {
		border: 0;
		background: transparent;
	}

	.x-plain-header-text {
		color: #333333;
	}
}

.modal {
	padding: 15px !important;

	.x-plain-bl {
		position: relative;
		right: -3px;
	}

	.x-plain-body, .x-plain-footer, .wizard {
		padding: 0 !important;
	}

	.x-plain-footer, td.x-toolbar-cell {
		padding: 0px !important;
	}

	fieldset {
		width: 100%;
	}

	td {
		&.x-btn-mr {
			width: 0px !important;
		}

		&.x-btn-bc, &.x-btn-br, &.x-btn-bl {
			height: 0px !important;
		}
	}

	.x-plain-header-text {
		padding-top: 0px !important;
		position: relative;
		top: -4px;
		padding-bottom: 8px !important;
	}

	.x-plain-bl {
		padding-top: 12px;
	}

	.x-btn-medium {
		tr:last-child, td.x-btn-mr {
			display: none;
		}
	}

	.ui-tabs-panel {
		border-color: #D0D0D0 !important;
		border-radius: 4px !important;
		border-top-left-radius: 0 !important;
	}
}

.modal .ui-tabs-panel {
	background-clip: padding-box;
	border-radius: 0 0 5px 5px;
	min-height: 150px;
	background: #FFF;
	border: 1px solid #D0D0D0;
	border-top-color: #CCC;
	padding: 20px 15px 20px;
}

#modal_sidenav {
	background: #FFF;
	border: 1px solid #D0D0D0;
	padding: 10px;
	float: left;
	margin-right: 15px;
	width: 228px;

	h3 {
		font-size: 15px;
		font-weight: bold;
		color: #333333;
		padding-bottom: 5px;
		border-bottom: 1px dashed #DDD;
		margin-bottom: 10px;
	}

	p {
		margin-bottom: 10px;
	}

	ul li {
		a {
			border-top-color: transparent;
			padding: 0 5px;
			margin: 0;

			&:hover {
				background-color: #FFF;
				background-image: none;
			}
		}

		&:first-child a {
			border-top: 1px solid #D1D2D4;
		}

		a.current {
			border: 1px solid rgba(123, 123, 123, 0.1);
			cursor: default;

			span {
				background: none;

	            &::after {
		            color: $input-gray;
		            content: char-from-code($font-icon-angle-right);
		            font-family: 'alkami';
		            font-size: 10px;
		            position: absolute;
		            right: 5px;
	            }
			}
		}
	}
}

#cmsInterstitialDlg img {
    max-width: 100%;
}

#modal_sidenav ul li a.current, table tr.title-bar td {
    appearance: none;
	background-image: none;
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.1) 100%, rgba(0, 0, 0, 0.3) 100%);
}

.prompt {
	z-index: 999999;
	position: absolute;
	padding-bottom: 10px;

	.message {
		display: block;
		color: #333333;
		font-size: 95%;
	}

	.button {
		margin-right: 5px;
		margin-top: 15px;
	}
}
