// Base Styles
//
// This file is loaded in the Default/Styles.desktop.cshtml file


// Variables
$light-gray: #707070;
$input-border: 1px solid $light-gray;
$input-gray: $light-gray;
$error-red: #C11826;

@import 'includes/_font-icon-variables';

// Import Bootstrap modules
@import 'vendor/bootstrap/_bootstrap-modal';
@import 'vendor/bootstrap/_bootstrap-select';

// Import other setup stuff
@import '_fonts';
@import '_errors';
@import '_gridly';
@import '_tooltips';

// Import base style partials
@import 'includes/base-partials/_accordion';
@import 'includes/base-partials/_accounts';
@import 'includes/base-partials/_aggregate-external-accounts';
@import 'includes/base-partials/_alerts';
@import 'includes/base-partials/_badges';
@import 'includes/base-partials/_buttons';
@import 'includes/base-partials/_checkboxes';
@import 'includes/base-partials/_clean-lists';
@import 'includes/base-partials/_content';
@import 'includes/base-partials/_dates';
@import 'includes/base-partials/_disclosures';
@import 'includes/base-partials/_dropdowns';
@import 'includes/base-partials/_empty';
@import 'includes/base-partials/_error';
@import 'includes/base-partials/_filters';
@import 'includes/base-partials/_footer';
@import 'includes/base-partials/_footnotes';
@import 'includes/base-partials/_form-elements';
@import 'includes/base-partials/_generic';
@import 'includes/base-partials/_grid';
@import 'includes/base-partials/_header';
@import 'includes/base-partials/_help-content';
@import 'includes/base-partials/_helpers';
@import 'includes/base-partials/_instructions';
@import 'includes/base-partials/_links';
@import 'includes/base-partials/_lists';
@import 'includes/base-partials/_loaders';
@import 'includes/base-partials/_misc';
@import 'includes/base-partials/_modals';
@import 'includes/base-partials/_module-boxes';
@import 'includes/base-partials/_notifications';
@import 'includes/base-partials/_password-togglefication';
@import 'includes/base-partials/_pdf-confirmation';
@import 'includes/base-partials/_placeholders';
@import 'includes/base-partials/_popovers';
@import 'includes/base-partials/_primary-nav';
@import 'includes/base-partials/_profile';
@import 'includes/base-partials/_progress-bar';
@import 'includes/base-partials/_progress-navigation';
@import 'includes/base-partials/_radio-buttons';
@import 'includes/base-partials/_related-content';
@import 'includes/base-partials/_shame';
@import 'includes/base-partials/_statuses';
@import 'includes/base-partials/_switches';
@import 'includes/base-partials/_system-alerts';
@import 'includes/base-partials/_tables';
@import 'includes/base-partials/_tabs';
@import 'includes/base-partials/_titlebar';
@import 'includes/base-partials/_tooltips';
@import 'includes/base-partials/_wizards';
@import 'includes/base-partials/_x-window';
