﻿// Content Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base content area styles

/** public **/
$content-width: 724px;

body.public div#primary_widget_outer {
	width: 1005px;

	&.has_sidebar div#primary_widget {
		width: $content-width !important;
	}


}

#main {
	margin-top: 30px;
	margin-bottom: 20px;
}

#main,
body.Authentication div#main,
body.Registration div#main,
body.Client div#main {
	display: flex !important;
	justify-content: center;
	min-height: auto;
}

#primary_widget_outer {
	display: flex;
	justify-content: space-between;
	background-color: #FFFFFF;
	border-radius: 5px;
	border: 0;
	box-shadow: 0 1px 5px rgba(51, 51, 51, 0.70);
	flex-shrink: 0;
	width: 900px;

	&:not(:only-child) {
		#primary_widget_title {
			border-radius: 0 !important;

			#related_content {
				display: none;
			}
		}
	}
}

#primary_widget {
	background-color: transparent;
	background-position: 50% 49px;
	border: 0 none;
    padding: 0;
	flex-grow: 1;
	width: 100%;
}

#primary_widget_title {
	height: 36px;
	padding: 15px 15px 10px;
	// I apologize for the magic number. In order to not have the widget title area to shift
	// when the title loads, we need to set a min height.
	min-height: 62px;
	border-radius: 5px 5px 0 0;

	h2 {
		color: #333333;
		float: left;
		font-size: 20px;
		font-weight: bold;
		margin: 3px 0 0 10px;
		text-shadow: 0 0 1px #fff;
	}
}

body.public div#primary_widget_title {
	background: none repeat scroll 0 0 transparent;
}

h1#content_header,
div#primary_widget_title h1,
div#primary_widget_title h2 {
	color: #545554;
	font-size: 26px;
    font-weight: 400;
	margin-left: 0;
	padding-bottom: 11px;
}

div#primary_widget_title hr {
    display: none;
}

div#primary_widget_content {
	min-height: 630px;
	position: relative;
	padding: 20px 15px 15px;
	clear: both;
	margin-top: -1px;

  	img {
        max-width: 100%;
    }
}

div#widget_overlay {
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
}

div.main-content {
	padding-left: 0;
	overflow: visible;
	// Necessary to fix DEV-13600
	clear: both;
}

div#ext-gen6.x-panel-bbar {
	width: auto;
}

.articles-component {
	.article {
		font-size: 1.2rem;
		font-weight: normal;
		color: #333333;
	}

	.article__date {
		color: #707070;
		display: block;
		margin: 2px 0 5px;
	}

	.article__title {
		font-size: 1.3rem;
		font-weight: bold;
		color: #333333;
		display: block;
		margin-top: 7px;
	}

	.article__title a {
		color: #333333;
	}

	.article__callout-link {
		display: block;
		margin-top: 5px;
	}
}
