﻿// Base Grid Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base grid styles

td.sort-desc .x-grid3-hd-inner, td.sort-asc .x-grid3-hd-inner {
	color: #396492;
}

.x-grid3-row-alt {
	background: #EEEEEE;
	border: 1px solid #D5D5D5;
}

.x-grid3-row-alt {
	border: none;
}