﻿// Base Footer Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base footer styles
.footer-wrapper {
	margin-top: auto !important;
}
div#footer {
	margin: 10px auto 0;
	overflow: hidden;
    padding-bottom:20px;
	width: 1005px;
}

div#footer_container {
	float: right;
	width: 900px;
}

body.public div#footer_container {
	width: 1005px;
}

div#footer p.copyright {
    margin: 10px 0;
    width: 40%;

  // Temporary styling
	clear: left;
	float: left;
}

.public div#footer p.copyright {
	width: auto;
}

div#footer ul#footer_nav {
	margin: 0;
	padding: 0;
	overflow: hidden;
}

div#footer ul#footer_nav li {
	display: block;
	float: left;
	font-weight: bold;
	padding-right: 30px;
}

div#footer ul#footer_nav a {
	font-weight: bold;
	text-decoration: none;
}

div#footer ul#notices {
	width: 530px;

  // Temporary styling
	float: right;
}

div#footer ul#notices li {
    background-repeat: no-repeat;
    display: block;
    float: left;
    font-size: 10px;
    padding-left: 50px;
    padding-right: 10px;
    width: 180px;
}

div#footer ul#notices li p {
    line-height: 150%;
}

div#footer ul#notices li#ehl {
    background: url("https://assets.orb.alkamitech.com/production/assets/global/images/notice_icon_ehl.png") no-repeat scroll 0 0;
    min-height: 25px;
}

div#footer ul#notices li#asi {
    background: url("https://assets.orb.alkamitech.com/production/assets/global/images/notice_icon_asi.png") no-repeat scroll 0 0;
    padding-left: 60px;
}

div#footer ul#notices li#ncua {
    background: url("https://assets.orb.alkamitech.com/production/assets/global/images/notice_icon_ncua.png") no-repeat scroll 0 0;
    padding-left: 65px;
}

#language_item_template {
	display: none;
}

.language-toggle {
	background-color: #333333;
	border-top: 1px solid #777;
	text-align: center;

	.language-item {
		color: #fff;
		display: inline-block;
		font-size: 15px;
		line-height: 18px;
		padding: 10px;
		text-transform: uppercase;
	}

	.current {
		display: none;
	}
}

.language-toggle-modal {
    .loader {
        background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/loader_large.gif");
        background-position: 50%;
        background-repeat: no-repeat;
        height: 32px;
    }

    .loader-container p {
        margin-top: 10px;
        text-align: center;
    }
}
