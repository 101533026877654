/*-----------------------------------*\
	$GRIDLY
	What a grid!
	It will drive you mad by the end of it.

  @author: Lance Turri

  Usage
  =====
	Assuming default values:
	<div class="grid">
		<div class="six columns">
			A half-width column.
		</div>
		<div class="three columns offset by-three">
			A quarter, offset by its own width. You get this, right?
		</div>
	</div>

	Customisation
	=============
	.without-gutter
		class="three columns without-gutter with-separation-m centered-h

	That's it. Have fun.

\*-----------------------------------*/

@mixin column-width($number-of-columns) {
	width: ($number-of-columns / 12) * 100%;
}

@mixin offset-margin($number-of-columns) {
	margin-left: ($number-of-columns / 12) * 100%;
}

$padding-width-s: 10px;
$padding-width-m: 15px;
$padding-width-l: 20px;
$padding-width-xl: 25px;

.grid {
	max-width: 100%;
	min-width: 100%;

	&:after {
		clear: both;
		content: "";
		display: table;
	}

	&.without-gutter {
		&:first-of-type {
			padding-left: 0;
		}

		&:last-of-type {
			padding-right: 0;
		}
	}
}

.column, .columns {
	box-sizing: border-box;
	float: left;
	padding-left: $padding-width-s;
	padding-right: $padding-width-s;

	// Nested columns or columns with class .without-gutter do not have outside padding
	& .column,
	& .columns,
	&.without-gutter {
		&.alpha,
		&:first-of-type {
			padding-left: 0;
		}

		&.omega,
		&:last-of-type {
			padding-right: 0;
		}
	}
	// Column separation modifiers
	&.without-separation {
		padding-left: 0;
		padding-right: 0;
	}

	&.with-separation-m {
		padding-left: $padding-width-m;
		padding-right: $padding-width-m;
	}

	&.with-separation-l {
		padding-left: $padding-width-l;
		padding-right: $padding-width-l;
	}

	&.with-separation-xl {
		padding-left: $padding-width-xl;
		padding-right: $padding-width-xl;
	}
	// Content position modifiers
	&.centered-h {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	&.near-the-bottom {
		vertical-align: bottom;
	}

	&.in-the-middle {
		vertical-align: middle;
	}
	// Begin the columns!
	&.one {
		@include column-width(1);
	}

	&.two {
		@include column-width(2);
	}

	&.three {
		@include column-width(3);
	}

	&.four {
		@include column-width(4);
	}

	&.five {
		@include column-width(5);
	}

	&.six {
		@include column-width(6);
	}

	&.seven {
		@include column-width(7);
	}

	&.eight {
		@include column-width(8);
	}

	&.nine {
		@include column-width(9);
	}

	&.ten {
		@include column-width(10);
	}

	&.eleven {
		@include column-width(11);
	}

	&.twelve {
		@include column-width(12);
	}
}

.offset {
	&-by-one {
		@include offset-margin(1);
	}

	&-by-two {
		@include offset-margin(2);
	}

	&-by-three {
		@include offset-margin(3);
	}

	&-by-four {
		@include offset-margin(4);
	}

	&-by-five {
		@include offset-margin(5);
	}

	&-by-six {
		@include offset-margin(6);
	}

	&-by-seven {
		@include offset-margin(7);
	}

	&-by-eight {
		@include offset-margin(8);
	}

	&-by-nine {
		@include offset-margin(9);
	}

	&-by-ten {
		@include offset-margin(10);
	}

	&-by-eleven {
		@include offset-margin(11);
	}
}