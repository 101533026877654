﻿// Password Togglefication Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base password togglefication styles

.password-togglefication {
    position: relative;
  }
  .password-togglefication-button {
    background: none;
    border: none;
    color: #4371AD;
    font-size: 10px;
    text-transform: lowercase;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 40px;
  
    &:hover,
    &:focus {
        color: #447dab;
    }
  }
  
  .password-togglefication input,
  .password-togglefication input[type=text],
  .password-togglefication input[type=password] {
      padding-right: 40px;
  }
  
  form .password-togglefication {
      float: left;
  }