/*!
 * Bootstrap-select v1.7.0 (http://silviomoreto.github.io/bootstrap-select/)
 *
 * Copyright 2013-2014 bootstrap-select
 * Licensed under MIT (https://github.com/silviomoreto/bootstrap-select/blob/master/LICENSE)
 */

/*************************************************************************
 *
 *		This is the SCSS file for the bootstrap-select plugin.
 *		It contains all of the dependancies to allow the plugin to work.
 *		If you are already loading Boostrap's CSS file, DO NOT LOAD THIS!
 *		Please load the boostrap-select-only SASS file instead.
 *
 *************************************************************************/


.form-control {
	display: block;
	width: 100%;
	height: 34px;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #333333;
	background-color: #ffffff;
	background-image: none;
	border: 1px solid #D0D0D0;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control:focus {
	border-color: #66afe9;
	outline: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::-moz-placeholder {
	color: #707070;
	opacity: 1;
}

.form-control:-ms-input-placeholder {
	color: #707070;
}

.form-control::-webkit-input-placeholder {
	color: #707070;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
	cursor: not-allowed;
	background-color: #eeeeee;
	opacity: 1;
}

textarea.form-control {
	height: auto;
}

input[type="search"] {
	-webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	input[type="date"],
	input[type="time"],
	input[type="datetime-local"],
	input[type="month"] {
		line-height: 34px;
	}

	input[type="date"].input-sm,
	input[type="time"].input-sm,
	input[type="datetime-local"].input-sm,
	input[type="month"].input-sm,
	.input-group-sm input[type="date"],
	.input-group-sm input[type="time"],
	.input-group-sm input[type="datetime-local"],
	.input-group-sm input[type="month"] {
		line-height: 30px;
	}

	input[type="date"].input-lg,
	input[type="time"].input-lg,
	input[type="datetime-local"].input-lg,
	input[type="month"].input-lg,
	.input-group-lg input[type="date"],
	.input-group-lg input[type="time"],
	.input-group-lg input[type="datetime-local"],
	.input-group-lg input[type="month"] {
		line-height: 46px;
	}
}

.form-group {
	margin-bottom: 15px;
}

.radio,
.checkbox {
	position: relative;
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
}

.radio label,
.checkbox label {
	min-height: 20px;
	padding-left: 20px;
	margin-bottom: 0;
	font-weight: normal;
	cursor: pointer;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
	position: absolute;
	margin-left: -20px;
	margin-top: 4px \9;
}

.radio + .radio,
.checkbox + .checkbox {
	margin-top: -5px;
}

.radio-inline,
.checkbox-inline {
	display: inline-block;
	padding-left: 20px;
	margin-bottom: 0;
	vertical-align: middle;
	font-weight: normal;
	cursor: pointer;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
	margin-top: 0;
	margin-left: 10px;
}

input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="checkbox"].disabled,
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"] {
	cursor: not-allowed;
}

.radio-inline.disabled,
.checkbox-inline.disabled,
fieldset[disabled] .radio-inline,
fieldset[disabled] .checkbox-inline {
	cursor: not-allowed;
}

.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
	cursor: not-allowed;
}

.form-control-static {
	padding-top: 7px;
	padding-bottom: 7px;
	margin-bottom: 0;
}

.form-control-static.input-lg,
.form-control-static.input-sm {
	padding-left: 0;
	padding-right: 0;
}

.input-sm {
	height: 30px;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}

select.input-sm {
	height: 30px;
	line-height: 30px;
}

textarea.input-sm,
select[multiple].input-sm {
	height: auto;
}

.form-group-sm .form-control {
	height: 30px;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}

select.form-group-sm .form-control {
	height: 30px;
	line-height: 30px;
}

textarea.form-group-sm .form-control,
select[multiple].form-group-sm .form-control {
	height: auto;
}

.form-group-sm .form-control-static {
	height: 30px;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
}

.input-lg {
	height: 46px;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.33;
	border-radius: 6px;
}

select.input-lg {
	height: 46px;
	line-height: 46px;
}

textarea.input-lg,
select[multiple].input-lg {
	height: auto;
}

.form-group-lg .form-control {
	height: 46px;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.33;
	border-radius: 6px;
}

select.form-group-lg .form-control {
	height: 46px;
	line-height: 46px;
}

textarea.form-group-lg .form-control,
select[multiple].form-group-lg .form-control {
	height: auto;
}

.form-group-lg .form-control-static {
	height: 46px;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.33;
}

.has-feedback {
	position: relative;
}

.has-feedback .form-control {
	padding-right: 42.5px;
}

.form-control-feedback {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	display: block;
	width: 34px;
	height: 34px;
	line-height: 34px;
	text-align: center;
	pointer-events: none;
}

.input-lg + .form-control-feedback {
	width: 46px;
	height: 46px;
	line-height: 46px;
}

.input-sm + .form-control-feedback {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
	color: #3c763d;
}

.has-success .form-control {
	border-color: #3c763d;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-success .form-control:focus {
	border-color: #2b542c;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

.has-success .input-group-addon {
	color: #3c763d;
	border-color: #3c763d;
	background-color: #dff0d8;
}

.has-success .form-control-feedback {
	color: #3c763d;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
	color: #8a6d3b;
}

.has-warning .form-control {
	border-color: #8a6d3b;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-warning .form-control:focus {
	border-color: #66512c;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}

.has-warning .input-group-addon {
	color: #8a6d3b;
	border-color: #8a6d3b;
	background-color: #fcf8e3;
}

.has-warning .form-control-feedback {
	color: #8a6d3b;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
	color: #a94442;
}

.has-error .form-control {
	border-color: #a94442;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-error .form-control:focus {
	border-color: #843534;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.has-error .input-group-addon {
	color: #a94442;
	border-color: #a94442;
	background-color: #f2dede;
}

.has-error .form-control-feedback {
	color: #a94442;
}

.has-feedback label ~ .form-control-feedback {
	top: 25px;
}

.has-feedback label.sr-only ~ .form-control-feedback {
	top: 0;
}

.help-block {
	display: block;
	margin-top: 5px;
	margin-bottom: 10px;
	color: #707070;
}

@media (min-width: 768px) {
	.form-inline .form-group {
		display: inline-block;
		margin-bottom: 0;
		vertical-align: middle;
	}

	.form-inline .form-control {
		display: inline-block;
		width: auto;
		vertical-align: middle;
	}

	.form-inline .form-control-static {
		display: inline-block;
	}

	.form-inline .input-group {
		display: inline-table;
		vertical-align: middle;
	}

	.form-inline .input-group .input-group-addon,
	.form-inline .input-group .input-group-btn,
	.form-inline .input-group .form-control {
		width: auto;
	}

	.form-inline .input-group > .form-control {
		width: 100%;
	}

	.form-inline .control-label {
		margin-bottom: 0;
		vertical-align: middle;
	}

	.form-inline .radio,
	.form-inline .checkbox {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 0;
		vertical-align: middle;
	}

	.form-inline .radio label,
	.form-inline .checkbox label {
		padding-left: 0;
	}

	.form-inline .radio input[type="radio"],
	.form-inline .checkbox input[type="checkbox"] {
		position: relative;
		margin-left: 0;
	}

	.form-inline .has-feedback .form-control-feedback {
		top: 0;
	}
}

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 7px;
}

.form-horizontal .radio,
.form-horizontal .checkbox {
	min-height: 27px;
}

.form-horizontal .form-group {
	margin-left: -15px;
	margin-right: -15px;
}

@media (min-width: 768px) {
	.form-horizontal .control-label {
		text-align: right;
		margin-bottom: 0;
		padding-top: 7px;
	}
}

.form-horizontal .has-feedback .form-control-feedback {
	right: 15px;
}

@media (min-width: 768px) {
	.form-horizontal .form-group-lg .control-label {
		padding-top: 14.3px;
	}
}

@media (min-width: 768px) {
	.form-horizontal .form-group-sm .control-label {
		padding-top: 6px;
	}
}

.btn {
	display: inline-block;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: 1px solid transparent;
	white-space: nowrap;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	border-radius: 4px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

.btn:hover,
.btn:focus,
.btn.focus {
	color: #333333;
	text-decoration: none;
}

.btn:active,
.btn.active {
	outline: 0;
	background-image: none;
	-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
	cursor: not-allowed;
	pointer-events: none;
	opacity: 0.65;
	filter: alpha(opacity=65);
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-default {
	color: #333333;
	background-color: #ffffff;
	border-color: #D0D0D0;
}

.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
	color: #333333;
	background-color: #e6e6e6;
	border-color: #D0D0D0;
}

.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
	background-image: none;
}

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
	background-color: #ffffff;
	border-color: #D0D0D0;
}

.btn-default .badge {
	color: #ffffff;
	background-color: #333333;
}

.btn-primary {
	color: #ffffff;
	background-color: #428bca;
	border-color: #357ebd;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
	color: #ffffff;
	background-color: #3071a9;
	border-color: #285e8e;
}

.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
	background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
	background-color: #428bca;
	border-color: #357ebd;
}

.btn-primary .badge {
	color: #428bca;
	background-color: #ffffff;
}

.btn-success {
	color: #ffffff;
	background-color: #5cb85c;
	border-color: #4cae4c;
}

.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
	color: #ffffff;
	background-color: #449d44;
	border-color: #398439;
}

.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
	background-image: none;
}

.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
	background-color: #5cb85c;
	border-color: #4cae4c;
}

.btn-success .badge {
	color: #5cb85c;
	background-color: #ffffff;
}

.btn-info {
	color: #ffffff;
	background-color: #5bc0de;
	border-color: #46b8da;
}

.btn-info:hover,
.btn-info:focus,
.btn-info.focus,
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
	color: #ffffff;
	background-color: #31b0d5;
	border-color: #269abc;
}

.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
	background-image: none;
}

.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
	background-color: #5bc0de;
	border-color: #46b8da;
}

.btn-info .badge {
	color: #5bc0de;
	background-color: #ffffff;
}

.btn-warning {
	color: #ffffff;
	background-color: #f0ad4e;
	border-color: #eea236;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus,
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
	color: #ffffff;
	background-color: #ec971f;
	border-color: #d58512;
}

.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
	background-image: none;
}

.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
	background-color: #f0ad4e;
	border-color: #eea236;
}

.btn-warning .badge {
	color: #f0ad4e;
	background-color: #ffffff;
}

.btn-danger {
	color: #ffffff;
	background-color: #d9534f;
	border-color: #d43f3a;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
	color: #ffffff;
	background-color: #c9302c;
	border-color: #ac2925;
}

.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
	background-image: none;
}

.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
	background-color: #d9534f;
	border-color: #d43f3a;
}

.btn-danger .badge {
	color: #d9534f;
	background-color: #ffffff;
}

.btn-link {
	color: #428bca;
	font-weight: normal;
	border-radius: 0;
}

.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
	border-color: transparent;
}

.btn-link:hover,
.btn-link:focus {
	color: #2a6496;
	text-decoration: underline;
	background-color: transparent;
}

.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
	color: #707070;
	text-decoration: none;
}

.btn-lg,
.btn-group-lg > .btn {
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.33;
	border-radius: 6px;
}

.btn-sm,
.btn-group-sm > .btn {
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}

.btn-xs,
.btn-group-xs > .btn {
	padding: 1px 5px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}

.btn-block {
	display: block;
	width: 100%;
}

.btn-block + .btn-block {
	margin-top: 5px;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
	width: 100%;
}

.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-top: 4px solid;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
}

.dropup,
.dropdown {
	position: relative;
}

.dropdown-toggle:focus {
	outline: 0;
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	list-style: none;
	font-size: 14px;
	text-align: left;
	background-color: #ffffff;
	border: 1px solid #D0D0D0;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
}

.dropdown-menu.pull-right {
	right: 0;
	left: auto;
}

.dropdown-menu .divider {
	height: 1px;
	margin: 9px 0;
	overflow: hidden;
	background-color: #e5e5e5;
}

.dropdown-menu > li > a {
	display: block;
	padding: 3px 20px;
	clear: both;
	font-weight: normal;
	line-height: 1.42857143;
	color: #333333;
	white-space: nowrap;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
	text-decoration: none;
	color: #333333;
	background-color: rgba(150, 150, 150, 0.15);
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
	color: #ffffff;
	text-decoration: none;
	outline: 0;
	background-color: #e1e1e1;
}

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
	color: #707070;
}

.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
	text-decoration: none;
	background-color: transparent;
	background-image: none;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
	cursor: not-allowed;
}

.open > .dropdown-menu {
	display: block;
}

.open > a {
	outline: 0;
}

.dropdown-menu-right {
	left: auto;
	right: 0;
}

.dropdown-menu-left {
	left: 0;
	right: auto;
}

.dropdown-header {
	display: block;
	padding: 3px 20px;
	font-size: 12px;
	line-height: 1.42857143;
	color: #707070;
	white-space: nowrap;
}

.dropdown-backdrop {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 990;
}

.pull-right > .dropdown-menu {
	right: 0;
	left: auto;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
	border-top: 0;
	border-bottom: 4px solid;
	content: "";
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
	top: auto;
	bottom: 100%;
	margin-bottom: 2px;
}

@media (min-width: 768px) {
	.navbar-right .dropdown-menu {
		left: auto;
		right: 0;
	}

	.navbar-right .dropdown-menu-left {
		left: 0;
		right: auto;
	}
}

.btn-group,
.btn-group-vertical {
	position: relative;
	display: inline-block;
	vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
	position: relative;
	float: left;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover,
.btn-group > .btn:focus,
.btn-group-vertical > .btn:focus,
.btn-group > .btn:active,
.btn-group-vertical > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn.active {
	z-index: 2;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
	margin-left: -1px;
}

.btn-toolbar {
	margin-left: -5px;
}

.btn-toolbar .btn-group,
.btn-toolbar .input-group {
	float: left;
}

.btn-toolbar > .btn,
.btn-toolbar > .btn-group,
.btn-toolbar > .input-group {
	margin-left: 5px;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
	border-radius: 0;
}

.btn-group > .btn:first-child {
	margin-left: 0;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.btn-group > .btn-group {
	float: left;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
	border-radius: 0;
}

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
	outline: 0;
}

.btn-group > .btn + .dropdown-toggle {
	padding-left: 8px;
	padding-right: 8px;
}

.btn-group > .btn-lg + .dropdown-toggle {
	padding-left: 12px;
	padding-right: 12px;
}

.btn-group.open .dropdown-toggle {
	-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-group.open .dropdown-toggle.btn-link {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn .caret {
	margin-left: 0;
}

.btn-lg .caret {
	border-width: 5px 5px 0;
	border-bottom-width: 0;
}

.dropup .btn-lg .caret {
	border-width: 0 5px 5px;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
.btn-group-vertical > .btn-group > .btn {
	display: block;
	float: none;
	width: 100%;
	max-width: 100%;
}

.btn-group-vertical > .btn-group > .btn {
	float: none;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
	margin-top: -1px;
	margin-left: 0;
}

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
	border-radius: 0;
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
	border-bottom-left-radius: 4px;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
	border-radius: 0;
}

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.btn-group-justified {
	display: table;
	width: 100%;
	table-layout: fixed;
	border-collapse: separate;
}

.btn-group-justified > .btn,
.btn-group-justified > .btn-group {
	float: none;
	display: table-cell;
	width: 1%;
}

.btn-group-justified > .btn-group .btn {
	width: 100%;
}

.btn-group-justified > .btn-group .dropdown-menu {
	left: auto;
}

[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
}

.input-group {
	position: relative;
	display: table;
	border-collapse: separate;
}

.input-group[class*="col-"] {
	float: none;
	padding-left: 0;
	padding-right: 0;
}

.input-group .form-control {
	position: relative;
	z-index: 2;
	float: left;
	width: 100%;
	margin-bottom: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
	height: 46px;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.33;
	border-radius: 6px;
}

select.input-group-lg > .form-control,
select.input-group-lg > .input-group-addon,
select.input-group-lg > .input-group-btn > .btn {
	height: 46px;
	line-height: 46px;
}

textarea.input-group-lg > .form-control,
textarea.input-group-lg > .input-group-addon,
textarea.input-group-lg > .input-group-btn > .btn,
select[multiple].input-group-lg > .form-control,
select[multiple].input-group-lg > .input-group-addon,
select[multiple].input-group-lg > .input-group-btn > .btn {
	height: auto;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
	height: 30px;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}

select.input-group-sm > .form-control,
select.input-group-sm > .input-group-addon,
select.input-group-sm > .input-group-btn > .btn {
	height: 30px;
	line-height: 30px;
}

textarea.input-group-sm > .form-control,
textarea.input-group-sm > .input-group-addon,
textarea.input-group-sm > .input-group-btn > .btn,
select[multiple].input-group-sm > .form-control,
select[multiple].input-group-sm > .input-group-addon,
select[multiple].input-group-sm > .input-group-btn > .btn {
	height: auto;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
	display: table-cell;
}

.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
	border-radius: 0;
}

.input-group-addon,
.input-group-btn {
	width: 1%;
	white-space: nowrap;
	vertical-align: middle;
}

.input-group-addon {
	padding: 6px 12px;
	font-size: 14px;
	font-weight: normal;
	line-height: 1;
	color: #333333;
	text-align: center;
	background-color: #eeeeee;
	border: 1px solid #D0D0D0;
	border-radius: 4px;
}

.input-group-addon.input-sm {
	padding: 5px 10px;
	font-size: 12px;
	border-radius: 3px;
}

.input-group-addon.input-lg {
	padding: 10px 16px;
	font-size: 18px;
	border-radius: 6px;
}

.input-group-addon input[type="radio"],
.input-group-addon input[type="checkbox"] {
	margin-top: 0;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.input-group-addon:first-child {
	border-right: 0;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.input-group-addon:last-child {
	border-left: 0;
}

.input-group-btn {
	position: relative;
	font-size: 0;
	white-space: nowrap;
}

.input-group-btn > .btn {
	position: relative;
}

.input-group-btn > .btn + .btn {
	margin-left: -1px;
}

.input-group-btn > .btn:hover,
.input-group-btn > .btn:focus,
.input-group-btn > .btn:active {
	z-index: 2;
}

.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
	margin-right: -1px;
}

.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
	margin-left: -1px;
}

.label {
	display: inline;
	padding: .2em .6em .3em;
	font-size: 75%;
	font-weight: bold;
	line-height: 1;
	color: #ffffff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: .25em;
}

a.label:hover,
a.label:focus {
	color: #ffffff;
	text-decoration: none;
	cursor: pointer;
}

.label:empty {
	display: none;
}

.btn .label {
	position: relative;
	top: -1px;
}

.label-default {
	background-color: #777777;
}

.label-default[href]:hover,
.label-default[href]:focus {
	background-color: #333333;
}

.label-primary {
	background-color: #428bca;
}

.label-primary[href]:hover,
.label-primary[href]:focus {
	background-color: #3071a9;
}

.label-success {
	background-color: #5cb85c;
}

.label-success[href]:hover,
.label-success[href]:focus {
	background-color: #449d44;
}

.label-info {
	background-color: #5bc0de;
}

.label-info[href]:hover,
.label-info[href]:focus {
	background-color: #31b0d5;
}

.label-warning {
	background-color: #f0ad4e;
}

.label-warning[href]:hover,
.label-warning[href]:focus {
	background-color: #ec971f;
}

.label-danger {
	background-color: #d9534f;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
	background-color: #c9302c;
}

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after,
.btn-toolbar:before,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:before,
.btn-group-vertical > .btn-group:after {
	content: " ";
	display: table;
}

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after,
.form-horizontal .form-group:after,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:after {
	clear: both;
}

.center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.pull-right {
	float: right !important;
}

.pull-left {
	float: left !important;
}

.hide {
	display: none !important;
}

.show {
	display: block !important;
}

.invisible {
	visibility: hidden;
}

.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.hidden {
	display: none !important;
	visibility: hidden !important;
}

.affix {
	position: fixed;
}

@-ms-viewport {
	width: device-width;
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
	display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
	display: none !important;
}

@media (max-width: 767px) {
	.visible-xs {
		display: block !important;
	}

	table.visible-xs {
		display: table;
	}

	tr.visible-xs {
		display: table-row !important;
	}

	th.visible-xs,
	td.visible-xs {
		display: table-cell !important;
	}
}

@media (max-width: 767px) {
	.visible-xs-block {
		display: block !important;
	}
}

@media (max-width: 767px) {
	.visible-xs-inline {
		display: inline !important;
	}
}

@media (max-width: 767px) {
	.visible-xs-inline-block {
		display: inline-block !important;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.visible-sm {
		display: block !important;
	}

	table.visible-sm {
		display: table;
	}

	tr.visible-sm {
		display: table-row !important;
	}

	th.visible-sm,
	td.visible-sm {
		display: table-cell !important;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.visible-sm-block {
		display: block !important;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.visible-sm-inline {
		display: inline !important;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.visible-sm-inline-block {
		display: inline-block !important;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.visible-md {
		display: block !important;
	}

	table.visible-md {
		display: table;
	}

	tr.visible-md {
		display: table-row !important;
	}

	th.visible-md,
	td.visible-md {
		display: table-cell !important;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.visible-md-block {
		display: block !important;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.visible-md-inline {
		display: inline !important;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.visible-md-inline-block {
		display: inline-block !important;
	}
}

@media (min-width: 1200px) {
	.visible-lg {
		display: block !important;
	}

	table.visible-lg {
		display: table;
	}

	tr.visible-lg {
		display: table-row !important;
	}

	th.visible-lg,
	td.visible-lg {
		display: table-cell !important;
	}
}

@media (min-width: 1200px) {
	.visible-lg-block {
		display: block !important;
	}
}

@media (min-width: 1200px) {
	.visible-lg-inline {
		display: inline !important;
	}
}

@media (min-width: 1200px) {
	.visible-lg-inline-block {
		display: inline-block !important;
	}
}

@media (max-width: 767px) {
	.hidden-xs {
		display: none !important;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.hidden-sm {
		display: none !important;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.hidden-md {
		display: none !important;
	}
}

@media (min-width: 1200px) {
	.hidden-lg {
		display: none !important;
	}
}

.visible-print {
	display: none !important;
}

@media print {
	.visible-print {
		display: block !important;
	}

	table.visible-print {
		display: table;
	}

	tr.visible-print {
		display: table-row !important;
	}

	th.visible-print,
	td.visible-print {
		display: table-cell !important;
	}
}

.visible-print-block {
	display: none !important;
}

@media print {
	.visible-print-block {
		display: block !important;
	}
}

.visible-print-inline {
	display: none !important;
}

@media print {
	.visible-print-inline {
		display: inline !important;
	}
}

.visible-print-inline-block {
	display: none !important;
}

@media print {
	.visible-print-inline-block {
		display: inline-block !important;
	}
}

@media print {
	.hidden-print {
		display: none !important;
	}
}

/*************************************************************************
 *
 *    Bootstrap Select Plugin
 *
 *************************************************************************/

/*!
 * Bootstrap-select v1.6.3 (http://silviomoreto.github.io/bootstrap-select/)
 *
 * Copyright 2013-2014 bootstrap-select
 * Licensed under MIT (https://github.com/silviomoreto/bootstrap-select/blob/master/LICENSE)
 */

.bootstrap-select {
	/*width: 220px\9; IE8 and below*/
	width: 220px \0;
	/*IE9 and below*/
}

.bootstrap-select > .btn {
	width: 100%;
	padding-right: 25px;
}

.error .bootstrap-select .btn {
	border: 2px solid #c0392b;
}

.control-group.error .bootstrap-select .dropdown-toggle {
	border-color: #c0392b;
}

.warning .bootstrap-select .btn {
	border: 2px solid #d35400;
}

.control-group.warning .bootstrap-select .dropdown-toggle {
	border-color: #d35400;
}

.success .bootstrap-select .btn {
	border: 2px solid #27ae60;
}

.control-group.success .bootstrap-select .dropdown-toggle {
	border-color: #27ae60;
}

.bootstrap-select.fit-width {
	width: auto !important;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
	width: 220px;
}

.bootstrap-select.form-control {
	margin-bottom: 0;
	padding: 0;
	border: none;
}

.bootstrap-select.form-control:not([class*="col-"]) {
	width: 100%;
}

.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="col-"] {
	float: none;
	display: inline-block;
	margin-left: 0;
}

.bootstrap-select.btn-group.dropdown-menu-right,
.bootstrap-select.btn-group[class*="col-"].dropdown-menu-right,
.row-fluid .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
	float: right;
}

.form-search .bootstrap-select.btn-group,
.form-inline .bootstrap-select.btn-group,
.form-horizontal .bootstrap-select.btn-group,
.form-group .bootstrap-select.btn-group {
	margin-bottom: 0;
}

.form-group-lg .bootstrap-select.btn-group.form-control,
.form-group-sm .bootstrap-select.btn-group.form-control {
	padding: 0;
}

.form-horizontal .form-group {
	margin-left: 0px;
	margin-right: 0px;
}

.form-inline .bootstrap-select.btn-group .form-control {
	width: 100%;
}

.input-append .bootstrap-select.btn-group {
	margin-left: -1px;
}

.input-prepend .bootstrap-select.btn-group {
	margin-right: -1px;
}

.bootstrap-select.btn-group > .disabled {
	cursor: not-allowed;
}

.bootstrap-select.btn-group > .disabled:focus {
	outline: none !important;
}

.bootstrap-select.btn-group .btn .filter-option {
	display: inline-block;
	overflow: hidden;
	width: 100%;
	text-align: left;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.bootstrap-select.btn-group .btn .caret {
	position: absolute;
	top: 50%;
	right: 12px;
	margin-top: -2px;
	vertical-align: middle;
}

.bootstrap-select.btn-group[class*="col-"] .btn {
	width: 100%;
}

.bootstrap-select.btn-group .dropdown-menu {
	min-width: 100%;
	z-index: 1035;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
	position: static;
	border: 0;
	padding: 0;
	margin: 0;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.bootstrap-select.btn-group .dropdown-menu li {
	position: relative;
}

.bootstrap-select.btn-group .dropdown-menu li.disabled a {
	cursor: not-allowed;
}

.bootstrap-select.btn-group .dropdown-menu li a {
	border-bottom: 1px solid #f5f5f5;
	cursor: pointer;
}

.bootstrap-select.btn-group .dropdown-menu li a.opt {
	position: relative;
	padding-left: 2.25em;
}

.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
	display: none;
}

.bootstrap-select.btn-group .dropdown-menu li a span.text {
	display: inline-block;
}

.bootstrap-select.btn-group .dropdown-menu li small {
	padding-left: 0.5em;
}

.bootstrap-select.btn-group .dropdown-menu .notify {
	position: absolute;
	bottom: 5px;
	width: 96%;
	margin: 0 2%;
	min-height: 26px;
	padding: 3px 5px;
	background: #f5f5f5;
	border: 1px solid #D0D0D0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
	pointer-events: none;
	opacity: 0.9;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.bootstrap-select.btn-group .no-results {
	padding: 3px;
	background: #f5f5f5;
	margin: 0 5px;
}

.bootstrap-select.btn-group.fit-width .btn .filter-option {
	position: static;
}

.bootstrap-select.btn-group.fit-width .btn .caret {
	position: static;
	top: auto;
	margin-top: -1px;
}
/* Remove Checkmark for the time being.
.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
  background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png");
  background-position: -97px -414px;
  content: " ";
  display: inline-block;
  height: 20px;
  margin-top: -10px;
  position: absolute;
  right: 5px;
  top: 50%;
  width: 20px;
}

.accountpicker .bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
  display: inline-block;
  left: 6px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
}
*/

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
	margin-right: 34px;
}

.bs-searchbox,
.bs-actionsbox {
	padding: 4px 8px;
}

.bs-actionsbox {
	float: left;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.bs-actionsbox .btn-group button {
	width: 50%;
}

.bs-searchbox + .bs-actionsbox {
	padding: 0 8px 4px;
}

.bs-searchbox input.form-control {
	margin-bottom: 0;
	width: 100%;
}

.mobile-device {
	position: absolute;
	top: 0;
	left: 0;
	display: block !important;
	width: 100%;
	height: 100% !important;
	opacity: 0;
}

/*************************************************************************
 *
 *    Begin Custom Styles
 *
 *************************************************************************/

/* Bootstrap Overrides */
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
	color: #333333;
	background-color: #e1e1e1;
}

.dropdown-header {
	padding: 5px 20px;
	color: #707070;
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
	margin-bottom: 2px;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover,
.btn-group > .btn:focus,
.btn-group-vertical > .btn:focus,
.btn-group > .btn:active,
.btn-group-vertical > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn.active {
	outline: 0;
}

.select-picker *, .bootstrap-select * {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

/* To get rid of the dotted outline in firefox. */
button::-moz-focus-inner {
	border: 0;
}

/* Current Styles */
.bootstrap-select button {
	background-color: #FFFFFF;
	box-shadow: 0 1px 3px #D9D9D9 inset;
	border: 1px solid #707070;
	border-radius: 3px;
	color: #333333;
	cursor: pointer;
	display: block;
	font-size: 12px;
	height: 34px;
	margin: 0;
	outline: none;
	padding: 9px 30px 8px 8px;
	position: relative;
	width: 100%;
}

.bootstrap-select button .filter-option {
	float: none !important;
	line-height: 1.2;
}

.bootstrap-select button:focus,
.bootstrap-select button:active {
	outline: none;
}

.bootstrap-select button, .bootstrap-select ul {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.bootstrap-select a {
	outline: none;
}

.bootstrap-select .dropdown-toggle:after {
	background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png");
	background-position: -94px -12px;
	content: " ";
	display: block;
	height: 20px;
	margin-top: -10px;
	position: absolute;
	right: 1px;
	top: 50%;
	-moz-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	width: 20px;
}

.bootstrap-select.open .dropdown-toggle:after {
	transform: rotate(180deg);
}

.bootstrap-select.btn-group .dropdown-menu li a.opt {
	text-decoration: none;
}

/* Accounts in Dropdown CSS */
.bootstrap-select .account-dropdown {
	border-bottom: 1px solid #E8E8E8;
	padding: 11px 10px;
}

.bootstrap-select .account-dropdown-small {
	border-bottom: 1px solid #E8E8E8;
	padding: 7px 20px;
}

/* Deprecated, use account-verbose instead */
.account-medium:before,
.account-verbose:before {
	border-radius: 2px;
	content: " ";
	display: block;
	height: 45px;
	left: 0;
	position: absolute;
	top: 0;
	width: 10px;
}

/* Deprecated, use account-simple instead */
.account-small:before,
.account-simple:before {
	border-radius: 2px;
	content: " ";
	display: block;
	height: 15px;
	left: 0;
	position: absolute;
	top: 0;
	width: 8px;
}

/* Account Dropdown - Size Medium */
.bootstrap-select.btn-group.verbose dropdown-menu {
  max-width: 100%;
}

.account-verbose,
.account-medium {
	display: inline-block;
	height: 45px;
	padding-left: 20px;
	position: relative;
	width: 100%;

	h4 {
		color: #333333;
		font-size: 13px;
		font-weight: bold;
		line-height: 14px;
		margin-bottom: 1px;
		margin-top: 3px;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.account-number {
		color: #333333;
		display: block;
		font-size: 11px;
		line-height: 12px;
		margin-top: 14px;
	}

	.amount {
		color: #333333;
		display: block;
		float: right;
		padding-right: 10px;
		text-align: right;
	}
}

/* Account Dropdown - Size Small */
.account-simple,
.account-small {
	display: inline-block;
	height: 15px;
	padding-left: 15px;
	position: relative;
	width: 100%;

	h4 {
		color: #333333;
		display: inline-block;
		font-size: 12px;
		font-weight: bold;
		height: 15px;
		line-height: 15px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 55%;
	}

	.account-number {
		color: #333333;
		display: inline-block;
		font-size: 11px;
		font-style: italic;
		line-height: 14px;
		margin-top: 1px; /* Solves an issue with Firefox */
		text-align: right;
		vertical-align: top;
		width: 45%;
	}
}

/* Account listing in button element after being selected. */
button.dropdown-toggle.select-picker {
	.account-medium,
	.account-small,
	.account-verbose,
	.account-simple {
		display: inline-block;
		height: 15px;
		text-align: left;
		width: 100%;

		&::before {
			height: 15px;
		}

		h4 {
			display: inline-block;
			margin-bottom: 0;
			margin-top: 0;
			max-width: 75%;
			padding-right: 5px;
			text-align: left;
			width: auto;
		}

		.account-number {
			color: #333333;
			display: inline-block;
			float: none;
			font-size: 11px;
			font-style: italic;
			line-height: 15px;
			margin-top: 0;
			overflow: hidden;
			text-align: left;
		}
	}
}

.dropdown-menu .select-picker h4 {
	color: #333333;
}

/* Alkami Specific Overrides */
.select-picker .selected a,
.select-picker .selected:active a {
	border-bottom: 1px solid rgba(172, 172, 172, 0.35) !important;
}

.select-picker .selected,
.select-picker .selected:active {
	background-color: rgba(172, 200, 217, 0.5);
	background-image: none;
	border-color: transparent;
    color: #000000 !important;
	text-shadow: none;
}

.select-picker .selected:hover {
	background-color: rgba(31, 125, 182, 0.35);
}

.bootstrap-select.open button,
.bootstrap-select button.select-picker:focus {
    background-color: #ffffff !important;
    border-color: #91B2D3 !important;
    box-shadow: inset 1px 1px 2px #e9e9e9, 0 0 4px rgba(145, 178, 211, 0.35) !important;
}

.bootstrap-select button {
	-moz-box-shadow: none;
	box-shadow: none;
}

.bootstrap-select .select-picker.btn-default {
	background-image: none;
	box-shadow: 0 1px 3px #D9D9D9 inset;
}

.bootstrap-select .select-picker.btn-default:hover,
.bootstrap-select .select-picker.btn-default:focus {
	background-color: #ffffff;
}

.bootstrap-select.open {
	position: relative;
}

.bootstrap-select.btn-group .dropdown-menu {
	border-radius: 3px;
	min-height: 0 !important;
	overflow: hidden;
	padding: 0;
}

.bootstrap-select .dropdown-menu > li > a {
    font-size: 13px;
    height: 32px;
    line-height: 14px;
    padding: 8px 6px;
	text-decoration: none;
}

.bootstrap-select.verbose .dropdown-menu > li > a {
	height: auto;
}
