﻿// Form Element Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base form element styles

.slide-content .search-form {
	width: 847px;
	margin: 12px auto 12px;
	overflow: hidden;

	.form-cols {
		overflow: hidden;
		border-bottom: 1px dashed #D5D5D5;
		margin-bottom: 10px;
		padding: 0 0 20px 0;
	}

	ul {
		width: 417px;
		float: left;
		margin-left: 13px;

		&:first-child {
			margin-left: 0;
		}

		li {
			overflow: hidden;

			label {
				padding: 7px 0 0 0;
				font-size: 12px;
				width: 150px;
			}
		}
	}

	input#date_range {
		width: 186px;
	}

	ul {
		li.range {
			input[type=text] {
				width: 89px;
			}

			div.range-field {
				float: left;
				position: relative;

				+ span {
					display: block;
					height: 30px;
					line-height: 30px;
					float: left;
					margin: 0 5px;
				}

				.ui-datepicker-trigger {
					right: 5px;
				}
			}
		}

		div.range-field span {
			float: left;
			display: block;
			margin: 10px 5px 0;
		}
	}
}

form {
	fieldset legend {
		font-weight: bold;
		font-size: 14px;
		color: #333333;
	}

	div.field {
		clear: both;
		position: relative;
		overflow: hidden;
	}

	label {
		display: block;
		float: left;
		text-align: left;
		// TODO: this is bad. There is no reason for labels to have
		// their padding/margin/width set globally.
		margin: 3px 4px 0 0;
		padding: 7px 7px 7px 0;
		width: 90px;
		color: #707070;
		font-weight: bold;
		font-size: 11px;

		&.inline {
			display: inline;
			float: none;
			width: auto;
		}
	}

	input {
		&[type=text], &[type=date], &[type=password], &[type=search] {
			background-color: #ffffff;
			border: $input-border;
			color: #333333;
			float: left;
			margin: 3px 0;
			padding: 6px;
			width: 218px;
        }
        &[type=text], &[type=date], &[type=password] {
			background-clip: padding-box;
			border-radius: 3px;
		}
	}

	textarea {
        background-clip: padding-box;
		background-color: #ffffff;
        border: $input-border;
        border-radius: 3px;
		color: #333333;
		float: left;
		margin: 3px 0;
		padding: 6px;
		width: 218px;
	}

	input {
		&.input-short[type="text"] {
			width: 75px;
		}

		&[type=text]:focus, &[type=date]:focus, &[type=password]:focus, &[type=search]:focus, &[type=number]:focus {
			outline: 0;
			background-color: #fff;
			border-color: #91B2D3;
			box-shadow: inset 1px 1px 2px #e9e9e9, 0 0 4px rgba(145, 178, 211, 0.8) !important;
		}
	}

	textarea:focus {
		outline: 0;
		background-color: #fff;
		border-color: #91B2D3;
		box-shadow: inset 1px 1px 2px #e9e9e9, 0 0 4px rgba(145, 178, 211, 0.8) !important;
	}

	.field_with_errors {
		label {
			color: $error-red;
		}

		input {
			&[type=text], &[type=date], &[type=password], &[type=number] {
				border-color: $error-red;
				background-color: #f5ebea;
				background-image: linear-gradient(to bottom, #f5ebea, #e4cbc8);
				box-shadow: inset 1px 1px 3px #f3e7e5;
			}
		}
	}

	input.error {
		border-color: $error-red;
		background-color: #f5ebea;
		background-image: linear-gradient(to bottom, #f5ebea, #e4cbc8);
		box-shadow: inset 1px 1px 3px #f3e7e5;
	}

	.field_with_errors input {
		&[type=text]:focus, &[type=date]:focus, &[type=password]:focus {
			border-color: $error-red;
			background-color: #f5ebea;
			background-image: linear-gradient(to bottom, #f5ebea, #e4cbc8);
			box-shadow: inset 1px 1px 3px #bbbbbb;
		}
	}

	input.error:focus {
		border-color: $error-red;
		background-color: #f5ebea;
		background-image: linear-gradient(to bottom, #f5ebea, #e4cbc8);
		box-shadow: inset 1px 1px 3px #bbbbbb;
	}
}

label.required:after {
	content: '*';
	color: $error-red;
	padding-left: 4px;
}

.input-wrapper {
	overflow: hidden;

	&.required:after {
		color: #990000;
		content: "*";
		margin-left: 5px;
	}
}

.x-form-focus, textarea.x-form-focus {
	border-color: #677F5C;
}

form input {
	&[type="text"] {
		&.decimal {
			text-align: right;
			width: 100px;
			box-sizing: border-box;
			font-weight: normal;
		}

		&.long {
			width: 100px;
			box-sizing: border-box;
			font-weight: normal;
		}
	}

	&.hasDatepicker[type="text"] {
		width: 100px;
		box-sizing: border-box;
		font-weight: normal;
		width: 100px;
	}

	&[type=text].int {
		box-sizing: border-box;
		width: 30px;
	}

	&[type="text"].zip {
		width: 80px;
	}
}

.ui-datepicker-trigger {
	cursor: pointer;
	position: relative;
	right: 25px;
	top: 50%;
	margin-top: 10px;
}

label.disabled {
	opacity: 0.5 !important;
	cursor: default;
}

ul.form-fields li {
	clear: both;

	label {
		width: 120px;
		padding-left: 0;
	}
}

/*********** modal form styling ********/

li.form-row {
	border-bottom: 1px solid #DEDEDE;
}

.form-row {
	label {
		margin-top: 0;
	}

	input {
		width: 206px;
	}
}

li.form-row {
	a.favorite {
		float: left;
		margin-top: 0;
		position: relative;
		top: 0;
		left: 0;
	}

	span.hint {
		float: right;
		font-style: italic;
		text-align: right;
		width: auto;
		margin-top: 1px;
	}

	&.last, &:last-child {
		border-bottom: 0 none;
	}

	div.field {
		margin-bottom: 0;
	}

	p {
		font-weight: normal;
	}
}

.form-header {
	background-color: #F9F9F9;

	span {
		font-weight: bold;
		display: inline-block;
	}
}

.form-header p {
	text-align: center;
	color: #333333;
}

form .form-row input {
	float: right;
	width: 206px;
}

.no-input {
	margin-top: 0 !important;
	margin-bottom: 0 !important;

	label {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
}

.field_with_errors span {
	color: $error-red;
}

.slide-content .search-form {
	ul li.save-search label {
		font-weight: normal;
	}

	input {
		&.button {
			float: right;
			padding: 5px 15px;
		}

		&[type=reset] {
			margin-right: 5px;
		}
	}
}

.x-form-field-wrap {
	float: left;

	.x-form-text {
		padding: 3px 6px;
	}

	.x-form-trigger {
		background-clip: padding-box;
		border-radius: 0 0 2px 0;
		right: 2px;
		top: 5px;
		border-style: solid;
		border-width: 0 0 1px 1px;
		border-color: #B5B8C8;
	}
}

#confirm-contact-info-form h4 {
	color: #333333;
	font-size: 15px;
	font-weight: bold;
	margin-bottom: 5px;
	margin-top: 20px;
	text-shadow: none;
}

.clear-input {
	color: #707070 !important;
	cursor: pointer;
	height: 30px;
	margin: 0 !important;
	opacity: 0;
	padding: 0 !important;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	transition: opacity 200ms ease-in-out;
	width: 30px;

	&::before {
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

.input-filled + .clear-input {
	opacity: 0.5;

	&:hover {
		opacity: 1;
	}
}

.search-wrapper {
	display: table;
	position: relative;
	width: 100%;
}
