﻿// Popover Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base popover styles

.popover-wrapper {
    position: relative;
}

.popover {
   background-color: #ffffff;
   border: 1px solid #D0D0D0;
   border-radius: 3px;
   box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.12);
   display: none;
   margin-top: 3px;
   opacity: 0.0;
   position: absolute;
   right: -75px;
   transition: 0.25s all linear;
   z-index: 2;

   &:target,
   &.open {
       display: block;
       opacity: 1.0;
   }

   /* Confirmation permutation - Yes or no action buttons */
   &.popover-confirm {
       &:before {
           border-bottom: 9px solid #e5e5e5;
           border-left: 7px solid transparent;
           border-right: 7px solid transparent;
           bottom: 100%;
           content: '';
           height: 0;
           left: 50%;
           margin-left: -7px;
           position: absolute;
           width: 0;
       }

       &:after {
           border-bottom: 8px solid #f5f5f5;
           border-left: 6px solid transparent;
           border-right: 6px solid transparent;
           bottom: 100%;
           content: '';
           height: 0;
           left: 50%;
           margin-left: -6px;
           position: absolute;
           width: 0;
       }

       .popover-header {
           background: #f5f5f5;
           border-bottom: 1px solid #e5e5e5;
           border-radius: 5px 5px 0 0;
           color: #333333;
           font-weight: 700;
           padding: 12px 0;
           text-align: center;
       }

       .popover-buttons {
           padding: 5px 7px;

           .button {
               margin: 5px 3px;
               width: 100px;
               text-align: center;
           }
       }
   }

   // Choice popover permutation - This includes a list of options to choose from.
   // See "Add new token" link on the Security tab of settings.
   &.popover-choices {
       &:before {
           border-bottom: 9px solid #e5e5e5;
           border-left: 7px solid transparent;
           border-right: 7px solid transparent;
           bottom: 100%;
           content: '';
           height: 0;
           left: 50%;
           margin-left: -7px;
           position: absolute;
           width: 0;
       }

       &:after {
           border-bottom: 8px solid #ffffff;
           border-left: 6px solid transparent;
           border-right: 6px solid transparent;
           bottom: 100%;
           content: '';
           height: 0;
           left: 50%;
           margin-left: -6px;
           position: absolute;
           width: 0;
       }

       .popover-choice {
           background: transparent;
           color: $light-gray;
           cursor: pointer;
           font-size: 12px;
           padding: 5px 10px;
           text-align: left;

           &:hover {
               background: #F0F4F9;
           }

           &:not(:last-child) {
               border-bottom: 1px solid #f4f4f4;
           }
       }
   }

   // Centers the popover on the container.
   &[data-popover-options*="centered"] {
       margin-top: 10px;
       right: 50%;
       transform: translateX(50%);
   }

   // Anchors the popover on the right side of the container.
   &[data-popover-options*="right"] {
       right: 15px;
       margin-top: 10px;

       &:before,
       &:after {
           left: 90%;
       }
   }
}
