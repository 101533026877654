﻿// Base Status Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base status styles

.label-status {
	&:after {
		border-radius: 3px;
		box-sizing: border-box;
		color: #ffffff;
		content: attr(data-status);
		display: inline-block;
		font-size: 10px;
		font-weight: bold;
		line-height: 20px;
		text-align: center;
		text-shadow: none;
		text-transform: uppercase;
		width: 80px;
	}
}

.label-status.authorized:after,
.list-item.authorized .label-status:after,
.label-status.posted:after,
.list-item.posted .label-status:after,
.label-status.succeeded:after,
.list-item.succeeded .label-status:after {
	background-color: #698c5b;
}

.label-status.pending:after,
.list-item.pending .label-status:after,
.label-status.expired:after,
.list-item.expired .label-status:after {
	background-color: #AAAAAA;
}

.label-status.failed:after,
.list-item.failed .label-status:after {
	background-color: #c00;
}

.label-status.rejected:after,
.list-item.rejected .label-status:after {
	background-color: #BC3333;
}

.label-status.abandoned:after,
.list-item.abandoned .label-status:after {
	background-color: #C6952E;
}

.label-status.canceled:after,
.list-item.canceled .label-status:after {
	background-color: #CE6D33;
}


// Check for availability (Settings and Registration)
// =============================================================================

.availability-icon {
	display: inline-block;
	margin-top: 8px;
	margin-left: 5px;

	.available {
		font-weight: bold;
		display: inline-block;
		color: #78AA63;
		background: url("https://assets.orb.alkamitech.com/production/assets/global/images/icon_checkmark.png") no-repeat;
		padding: 0 0 0 20px;
		margin-top: 8px;
	}

	.error {
		background: url("https://assets.orb.alkamitech.com/production/assets/global/images/icon_error_small.png") no-repeat;
		color: #982F24;
		font-weight: 700;
		padding: 0 0 0 20px;
	}
}

// Row statuses - When there are statuses instead of actions on the right of the row.
.row-status {
	position: absolute;
	right: 20px;

	.favorite {
		display: inline-block;
		margin-right: 40px;
	}

	.status-label {
		margin-right: 40px;
	}
}

.status-label {
	display: inline-block;

	&:before {
		border-radius: 3px;
		color: #ffffff;
		content: '';
		display: inline-block;
		font-size: 10px;
		font-weight: 700;
		line-height: 10px;
		padding: 5px 10px;
		text-align: center;
		text-transform: uppercase;
		width: 50px;
	}

	&.enabled:before {
		background-color: #307B21;
		content: attr(data-status);
	}

	&.disabled:before {
		background-color: #B4B4B4;
		content: attr(data-status);
	}

	&.active:before {
		background-color: #307B21;
		content: 'ACTIVE';
	}

	&.unactivated:before {
		background-color: #B4B4B4;
		content: 'UNACTIVATED';
	}

	&.positive:before {
		background-color: #307B21;
		content: attr(data-status);
	}

	&.negative:before {
		background-color: #C11826;
		content: attr(data-status);
	}

	&.warning:before {
		background-color: #D18334;
		content: attr(data-status);
	}

	&.info:before {
		background-color: #707070;
		content: attr(data-status);
	}

	&[data-status-options~="transformer"]:before {
		width: auto;
	}

	&[data-status-options~="inverted"]:before {
		color: #707070;
	}

	&[data-status-options~="inline"]:before {
		margin-right: 5px;
	}
}