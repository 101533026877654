﻿// Base Progress Navigation Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base progress navigation styles

ul#progress_nav {
	background-clip: padding-box;
	border-radius: 0 5px 0 0;
	list-style: none;
	background: #EEE;
	padding: 5px 5px 7px;
	border-color: #D0D0D0;
	border-bottom-color: #FFF;

	li {
		float: left;
		width: 130px;
    }

    .ui-state-default {
        border:none;
        background: none;
    }
}

.modal ul#progress_nav {
    background: none;
    border: none;
    padding: 0;

    li {
        background-clip: padding-box;
        border-radius: 0;
        background-color: #ffffff;
        background-image: linear-gradient(to bottom, #ffffff, #f1f1f3);
        border: 1px solid #D0D0D0;
        border-bottom: none;
        bottom: -1px;
        border-right: none;
        position: relative;
        padding: 0;
        width: auto;
        height: auto;
        margin-top: 4px;

        span.heading {
            padding: 10px 30px;
            margin: 0 !important;
            color: #333333;
            font-size: 12px;
            display: block;
            text-align: center;
            font-weight: bold;
        }

        &.ui-state-disabled span.heading {
            color: #ccc;
        }

        span.line {
            position: absolute;
            top: -1px;
            left: -1px;
            right: -1px;
            height: 2px;
            display: block;
            background: #ccc;
            z-index: 0;
        }

        &.ui-state-disabled span {
            &.line {
                background: #cccccc;
            }

            &.step {
                border-color: #D0D0D0;
                background-color: #fff;
                z-index: 5;
            }
        }

        &.ui-state-active span {
            &.line {
                top: -2px;
                width: 50%;
                left: auto;
                background: #cccccc;
            }

            &.step {
                top: -6px;
                background: #fff;
                z-index: 2;
            }
        }

        span.step {
            background-clip: padding-box;
            border-radius: 5px;
            padding: 0;
            width: 6px;
            height: 6px;
            background: #ffffff;
            border: 2px solid #D0D0D0;
            position: absolute;
            top: 0;
            left: 48%;
            top: -5px;
        }

        &:last-child {
            border-right: 1px solid #ddddde;
        }

        &.ui-state-active {
            background: #ffffff;
            padding-bottom: 1px;
            border-top-width: 2px;

            span.heading {
                padding-top: 9px;
            }
        }
    }

    .ui-state-active {
        border-top-color: #333333;
    }

    span {
        &.line, &.step {
            background-color: #333333;
            border-color: #333333;
        }
    }

    .ui-state-active {
        + li:not(.ui-state-disabled):before {
            content: '';
            position: absolute;
            left: -70%;
            top: -1px;
            z-index: 1;
            width: 70%;
            display: block;
            height: 2px;
            background: #444444;
        }

        &:before {
            content: '';
            position: absolute;
            left: -1px;
            top: -2px;
            z-index: 0;
            width: 1px;
            display: block;
            height: 2px;
            background: #444444;
        }
    }
}

ul#progress_nav li.ui-state-active {
	span.line, .step {
		background-image: none;
		background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.1) 100%, rgba(0, 0, 0, 0.3) 100%);
	}
}
