// @author Vanessa
// @created April 14th, 2017
// Styles for default error handling

// Error Message Styles
.error-msg {
  background-color: #FFFFFF;
  border: 1px solid rgba(196,15,43, 0.72);
  float: none;
  border-radius: 3px;
  display: flex;
  &.color {
    background-color: #C40F2B;
    max-height: 100%;
    display: flex;
    color: #FFFFFF;
    border-radius: 2px 0 0 2px;
    align-items: center;
    padding: 0 12px;
    .font-icon-error:before {
      font-size: 24px;
    }
  }
  .error-msg-text {
    font-size: 13px;
    color: #333333;
    line-height: 15px;
    margin: 15px;
    align-items: center;
    display: flex;
  }
}

// Inline Error Message
.inline-error {
  background: #C11826;
  border-radius: 3px;
  box-sizing: border-box;
  color: #F5F5F5;
  display: block;
  font-size: 12px;
  line-height: 135%;
  margin-top: 10px;
  padding: 10px;
  position: relative;
  width: 100%;

  &::before {
    border-bottom: 5px solid #C11826;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    content: '';
    left: 10px;
    position: absolute;
    top: -5px;
    width: 0;
    height: 0;
  }
}
