// Helper Classes
// Included in every theme via the _base.scss file
//
// This partial sets up our helper classes

.clear {
	clear: both;
	float: none;
}

.clearfix:after {
	content: " ";
	display: block;
	height: 0;
	clear: both;
}

.pull-left,
.left {
	float: left;
}

.pull-right,
.right {
	float: right;
}

.hide,
.hidden {
	display: none !important;
}

.inline-block {
	display: inline-block !important;
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.faded {
	opacity: 0.5;
}

.no-before::before {
	display: none !important;
}

.field-full {
    width: 100%;
}

.field-large {
    width: 400px;
}

.field-medium {
    width: 250px;
}

.field-small {
    width: 160px;
}

.no-edit-container {
    color: #8F8F8F !important;
    border: 0 none !important;
    background-color: transparent !important;
    background: transparent !important;
    box-shadow: none !important;
}
  
label.stacked {
    display: block;
    float: none;
    margin: 0 0 10px;
    padding-bottom: 0;
    width: auto;
}
 
.highlight {
	background: #F5F5F5;
}

.disabled, input[type=submit].disabled {
	opacity: 0.5;
	cursor: default;
}

.fade-container {
	transition: all 0.3s linear;
	height: 0 !important;
	opacity: 0 !important;
}

.line-divider:after {
	border-bottom: 1px solid #E6E6E6;
	content: " ";
	display: block;
	height: 1px;
	margin-bottom: 15px;
	margin-top: 10px;
	width: 100%;
}