﻿// System Alert Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base system alert styles

// Override font and inline styling added to the excerpt in Admin
#system_alert_content,
#system_alert_content font,
#system_alert_content span {
    font-size: 1.4rem;
    color: #333333 !important;
}

#system_alert_content h1,
#system_alert_content h2,
#system_alert_content h3,
#system_alert_content h4,
#system_alert_content h5,
#system_alert_content h6 {
    color: #333333 !important;
    font-weight: bold;
    margin-bottom: 5px;
}

#system_alert_content h1 {
    font-size: 2.0rem;
}

#system_alert_content h2 {
    font-size: 1.8rem;
}

#system_alert_content h3 {
    font-size: 1.6rem;
}

#system_alert_content h4,
#system_alert_content h5,
#system_alert_content h6 {
    font-size: 1.4rem;
}

#system_alert_content p {
    line-height: 1.5;
}

#system_alert_content p + p,
#system_alert_content img + *,
#system_alert_content * + img {
    margin-top: 10px;
}

#system_alert_content img {
    max-width: 100%;
}
