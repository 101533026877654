﻿// Date Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base date styles

.date.icon {
	padding-top: 20px;
	display: inline-block;
	width: 40px;
	height: 25px;
	text-align: center;
	background: transparent center top no-repeat;

	&.jan {
		background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/calendar_icons/01.gif");
	}

	&.feb {
		background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/calendar_icons/02.gif");
	}

	&.mar {
		background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/calendar_icons/03.gif");
	}

	&.apr {
		background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/calendar_icons/04.gif");
	}

	&.may {
		background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/calendar_icons/05.gif");
	}

	&.jun {
		background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/calendar_icons/06.gif");
	}

	&.jul {
		background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/calendar_icons/07.gif");
	}

	&.aug {
		background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/calendar_icons/08.gif");
	}

	&.sep {
		background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/calendar_icons/09.gif");
	}

	&.oct {
		background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/calendar_icons/10.gif");
	}

	&.nov {
		background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/calendar_icons/11.gif");
	}

	&.dec {
		background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/calendar_icons/12.gif");
	}

	.day {
		font-size: 16px;
		font-weight: bold;
		padding: 0;
		display: block;
		line-height: 16px;
	}
}

.list-item.selected .day {
	color: #707070;
	text-shadow: none;
}

table.ui-datepicker-calendar tr td.ui-datepicker-current-day a {
	appearance: none;
	background-image: none;
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.1) 100%, rgba(0, 0, 0, 0.3) 100%);
}