﻿// Base Notifications Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base notifications styles


.flash-banner {
    box-sizing: border-box;
    height: auto;
    transition: 0;
    width: 100%;
    margin-bottom: 40px;

}

#meta_header,
#content,
#flashMessage_container, {
    & > .flash-banner {
        position: fixed;
        left: 50%;
        margin-left: -50%;
        box-shadow: 0 20px 50px -5px rgba(0, 0, 0, 0.3);
        z-index: 9000;
        margin-bottom: 0;
    }
}

#content .flash-banner {
    top: 0;
}

.titlebar + #content .flash-banner {
    top: 45px;
}


div {
	&#meta_header div.flash {
		background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/flash_bg.png");
		background-repeat: repeat;
		box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.6);
		color: #FFFFFF;
	}

	&#primary_widget_content div.notice-hero {
		background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/flash_bg.png");
		background-repeat: repeat;
		box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.6);
		color: #FFFFFF;
		border-radius: 3px;
		box-shadow: none;
	}

	&#meta_header div.flash {
		box-sizing: border-box;
		height: auto;
		left: 50%;
		margin-left: -50%;
		padding: 25px;
		position: fixed;
		width: 100%;
		z-index: 9999;
	}

	&.flash.success, &.notice-hero {
		background-color: #689B3C;
		background-color: rgba(104, 155, 60, 0.95);
		text-shadow: 0 1px 0 #557E36;
	}

	&.flash {
		&.error {
			background-color: #C45413;
			background-color: rgba(196, 84, 19, 0.95);
			text-shadow: 0 1px 0 #AE4A16;
		}

		&.info {
			background-color: #5EA6ED;
			background-color: rgba(94, 166, 237, 0.95);
			text-shadow: 0 1px 0 #468DDD;
		}

		div.flash_container {
			background-position: 0 0;
			background-repeat: no-repeat;
			min-height: 40px;
			margin: 0 auto;
			position: relative;
			width: 1000px;
		}

		&.success div.flash_container {
			background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/flash_success_icon.png");
		}

		&.error div.flash_container {
			background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/flash_error_icon.png");
		}

		&.info div.flash_container {
			background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/flash_icon_info.png");
		}

		h2 {
			font-size: 15px;
			font-weight: bold;
			padding-left: 55px;
			padding-top: 11px;
			padding-right: 65px;
		}
	}

	&.sticky-footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		padding: 30px;
		background-color: #689B3C;
		background-color: rgba(104, 155, 60, 0.95);
	}

	&.sticky-inner {
		width: 1000px;
		margin: 0 auto;

		h2 {
			font-weight: bold;
			color: #fff;
		}
	}
}
