﻿// List Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base list styles

// This is seperated out of list becuase there are some instances in our application where the class of list-item is being used and not wrapped in list
.list-item {
    background-color: #F5F5F5;
    border: 1px solid #DEDEDE;
    color: #707070;

    &-rounded {
        border-radius: 3px;
    }
}

.list .list-item {
	clear: both;
	line-height: 20px;
	font-size: 12px;
	text-align: left;
	position: relative;
	display: block;
	padding: 10px 15px;
	margin: 2px 0;

	h4 {
		font-weight: bold;
		font-size: 14px;
	}

	.row-actions {
		margin-top: -20px;
		position: absolute;
		right: 10px;
		top: 50%;

		a {
			background-clip: padding-box;
			border-radius: 3px;
			background-color: #ffffff;
			float: right;
			color: #333333;
			padding: 3px 8px;
			margin: 6px 5px;
			background: #fff;
			border: 1px solid #D0D0D0;
			font-weight: bold;
			top: 50%;
			right: 0;
		}
	}

	&:hover .row-actions {
		display: block;
	}

	.bar {
		display: block;
		position: absolute;
		width: 6px;
		top: 0;
		left: 0;
		height: 100%;
		border-radius: 2px 2px 2px 2px;
	}
}

div.list-item.selected div.row-actions {
    text-shadow: none;
}

label.disabled.list-item {
    opacity: 0.5 !important;
    cursor: default;
}

.list-item.disabled {
	cursor: default;

	span.holder {
		background: #f0f0f0;
		border: 1px solid #D0D0D0;
		height: 9px;
		width: 9px !important;
		top: 3px;
		left: 3px;
		cursor: default;
	}
}

.category-list {
	border: 1px solid #B8BABA;
	background-color: #FFF;

	> li {
		> ul {
			border: 1px solid #B8BABA;
			background-color: #FFF;
		}

		ul {
			display: none;
			position: absolute;
			margin-top: -18px;
			left: 199px;
			width: 160px;
			z-index: 9999;
		}
	}

	li:hover {
		border-color: #B8BABA;
	}

	> li:hover {
		background: #dddddd url("https://assets.orb.alkamitech.com/production/assets/global/images/vendor/extjs/default/grid/arrow-right.png") no-repeat 100% 50%;

		ul {
			display: block;

			li {
				color: #333333;
			}
		}
	}
}

.x-combo-list.category-list {
	height: auto !important;
	background: transparent !important;
	border: 0;
	overflow: visible !important;

	.x-combo-list-inner {
		height: auto !important;
		background: transparent !important;
		border: 0;
		overflow: visible !important;
	}

	.manage {
		margin-top: 5px;
		padding-top: 5px;
		border-top: 1px solid #ccc;
		background: url("https://assets.orb.alkamitech.com/production/assets/global/images/icon_gear.png") no-repeat 98% 50%;
	}

	.split {
		margin-top: 5px;
		padding-top: 5px;
		border-top: 1px solid #ccc;
		background: url("https://assets.orb.alkamitech.com/production/assets/global/images/icon_split.png") no-repeat 98% 50%;
	}

	.manage:hover, .split:hover {
		background-color: #DDD;
	}
}

.split + .manage {
	border-top-color: transparent;
	margin-top: 0;
}