﻿// Loader Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base loader styles

.loading-indicator, .x-mask-loading div, .highcharts-loading span {
	background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/loader-small.gif");
	background-repeat: no-repeat;
	font-size: 12px;
	font-weight: normal;
	padding-left: 25px;
}

.ext-el-mask-msg, .x-mask-loading {
	background-color: #FFFFFF;
	background-image: none;
	border: 0 none;
	border-radius: 3px;
	box-shadow: 0 1px 5px rgba(51, 51, 51, 0.7);
	top: 20px !important;
}

.ext-el-mask-msg div {
	background-color: transparent;
	border: 0 none;
	color: #333333;
}

.large-loader p:after {
    background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/loader_large.gif");
    background-repeat: no-repeat;
    content: '';
    display: block;
    height: 33px;
    margin: 23px auto 0;
    width: 32px;
}

#widget_overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.5);
	z-index: 1000;

	.loading {
		color: #333333;
		width: 300px;
		height: 200px;
		background: url("https://assets.orb.alkamitech.com/production/assets/global/images/loader.gif") center top no-repeat;
		text-align: center;
		padding-top: 20px;
	}
}
