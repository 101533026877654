﻿// Base Account Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base account styles

// =============================================================================
// Legacy Account Styling
// =============================================================================

// This styling matches the account styling used on quick and classic transfer
// but has different markup and CSS so we can use it later for v2 widgets

// Medium Accounts
.account-medium.legacy {
  box-sizing: border-box;
  color: #333333;
  display: inline-block;
  font-size: 13px;
  height: 40px;
  padding-left: 20px;
  position: relative;
  width: 100%;
}

.account-medium.legacy:before {
  border-radius: 2px;
  content: " ";
  display: block;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 10px;
}

.account-medium.legacy .account-name {
  display: block;
  font-weight: bold;
  margin-bottom: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.account-medium.legacy .account-number {
  clear: left;
  display: block;
  float: left;
  font-weight: normal;
  font-style: italic;
}

.account-medium.legacy .detail {
  display: inline-block;
  float: right;
  height: 40px;
  position: relative;
  right: 0;
  text-align: right;
}

.account-medium.legacy .amount {
  display: block;
  float: right;
  font-weight: bold;
  line-height: 14px;
  position: relative;
  text-align: right;
}

.account-type-shared .account-name:after {
  background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/account_indicator_sprite.gif");
  background-color: #A3A4A4;
  background-position: 4px -57px;
  border-radius: 2px;
  content: "";
  display: inline-block;
  height: 5px;
  margin-bottom: -1px;
  margin-left: 5px;
  padding: 3px;
  width: 6px;
}

// Account Type Icons Legend
.bal.available {
  padding-left: 20px;
  position: relative;

  .select-picker .selected & {
	  color: #333333;
      border: $input-border;
  }
}

.select-picker .selected .bal.available{
    border: none;
}

.bal.available:before {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  content: "A";
  color: #707070;
  font-size: 8px;
  font-weight: 400;
  display: block;
  height: 11px;
  left: 0;
  line-height: 10px;
  margin-left: -2px;
  margin-top: -7px;
  padding-top: 2px;
  position: absolute;
  text-align: center;
  text-indent: 0;
  text-shadow: none;
  top: 50%;
  width: 13px;
  text-transform: uppercase;
}

.selected .bal.available:before {
	border-color: #D0D0D0;
}

// Invert icons inside of account dropdown
.selected .bal.available:before,
.x-combo-selected .bal.available:before {
  background: #ffffff;
  color: #707070;
}
