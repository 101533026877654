﻿// Button Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base button styles


button.action, input.button, span.button, .list-header {
	background-image: none;
}

table.button button {
	background-image: none;
}

input[type=submit], .title-bar, #filters, .filters {
	background-image: none;
}

a {
	&.cancel.button, &.secondary.button {
		background-image: none;
	}
}

input {
	&.cancel.button, &.secondary.button {
		background-image: none;
	}
}

a {
	&.action, &.button {
		background-image: none;
	}
}


table.button.secondary button, .delete-category span, .ui-progressbar-value, .list-header.selected, .ui-state-active .button {
	background-image: none;
}

// Primary Button
a.button,
input.button {
    box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
}

.button,
.ui-button,
input[type="submit"],
.x-window-plain .button button {
	background-clip: padding-box;
	border-radius: 3px;
	display: inline-block;
	cursor: pointer;
	padding: 4px 8px;
	color: #fff;
	border: 0;
	font-size: 12px;
	font-weight: bold;
	text-shadow: 0 -1px 1px #0f5571;
}

.ui-dialog .ui-dialog-buttonpane button,
input[type=submit],
.button, .ui-button,
.x-window-plain .button button {
	font-weight: normal;
}

.button.white {
	background-color: #fff;
	border: 1px solid #D0D0D0;
	border-radius: 3px;
	color: #333333;
	font-weight: bold;
	text-shadow: none;
	background-image: none;
	margin-top: 0;
	text-shadow: none !important;

	&:hover {
		background-color: #fff;
		border: 1px solid #D0D0D0;
		border-radius: 3px;
		color: #333333;
		font-weight: bold;
		text-shadow: none;
		background-image: none;
		margin-top: 0;
		text-shadow: none !important;
	}
}

a#export_transactions.button,
a#print_transactions.button {
	margin-left: 5px;
	position: static;
}

// Disabled Button
.button.x-item-disabled * {
    color: #ffffff !important;
}

.disabled button.x-btn-text {
    color: #ffffff !important;
}

button.disabled {
    cursor: default !important;
}

// Neutral Button
a.button.neutral,
input.button.neutral,
button.button.neutral {
	background-color: #ffffff;
	background-image: none;
	border: 1px solid #dfdfdf;
	color: #595959 !important;
	text-shadow: none;

	&:hover {
		background-color: #F5F5F5;
	}
}

// Link button | A <button> element that looks like a link
// Example: <button class="button-link">I look like a link!</button>
.button-link {
	border: none;
	background: none;
	color: #396492;
	outline: none;
	cursor: pointer;

	&:hover,
	&:focus {
		color: darken(#396492, 10%);
	}
}

// Action Button
// =============================================================================
a.action, button.action {
    background-color: #FFFFFF;
    border: $input-border;
    border-radius: 3px;
    box-sizing: border-box;
    color: $input-gray;
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    height: 32px;
    line-height: 20px;
    padding: 5px 12px 4px 35px;
    text-shadow: 0 1px 0 #FFFFFF;
    text-align: center;
}

a.action.no-icon {
    padding-left: 12px;
}

a.action.icon:before, button.action:before {
    height: 20px;
    margin-top: -8px;
    width: 20px;
}

a.action:hover, button.action:hover {
    color:#333333;
}

a.action.icon.attach:before {
content: char-from-code($font-icon-add);
    font-size: 16px;
}

a.icon.delete {
    color: #DD2626;
    padding-left: 17px;
}

a.icon.delete:hover {
    color: #CC0000;
}

a.action.icon.upload:before {
    background-position: -299px -276px;
}

button.action.icon.upload {
    cursor: pointer;
    position: relative;
}

button.action.icon.upload:before {
    background-position: -298px -274px;
}

a.action.icon.view-more {
    float: right;
    margin-right: 20px;
    padding-left: 10px;
    padding-right: 30px;
}
a.action.icon.view-more:before {
    content: char-from-code($font-icon-angle);
    font-size: 16px;
    font-family: "Alkami";
    left: auto;
    right: 5px;
}

a.action.icon.split:before,
#category_selection_modal .modal-footer div.split:before {
    content: char-from-code($font-icon-split);
    font-size: 16px;
    text-indent: 0 !important;
}

a.action.icon.add:before {
    background-position: 0 -279px;
}

a.action.icon.add-new span {
    content: char-from-code($font-icon-add);
    font-size: 16px;
}

a.action.icon.settings:before {
    background-position: -140px -157px;
}


// Row action button - absolutely positioned
.row-action-absolute {
    background-color: #ffffff;
    border: 1px solid #D0D0D0;
    border-radius: 4px;
    box-sizing: border-box;
    color: #333333;
    cursor: pointer;
    display: block;
    font-size: 12px;
    font-weight: bold;
    height: 44px;
    line-height: 19px;
    margin-top: -22px;
    padding: 12px 15px;
    position: absolute;
    right: 15px;
    top: 50%;
}

// Custom upload image button
// =============================================================================
.file-browse .file-upload-wrapper {
	text-align: center;

	input {
		display: none;
	}

	label {
		background: #f5f5f5;
		border: $input-border;
		border-radius: 3px;
		cursor: pointer;
		display: inline-block !important;
		line-height: 20px;
		padding: 7px 10px 7px 34px !important;
		position: relative;
		width: auto;

		&:hover {
			background: #eeeeee;
		}

		&:before {
			background: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png") no-repeat;
			background-position: -297px -276px;
			color: #707070;
			content: '';
			display: block;
			height: 20px;
			left: 9px;
			margin-top: -10px;
			position: absolute;
			top: 50%;
			width: 20px;
		}
	}
}

// Secondary Button
// =============================================================================
a.cancel.button,
a.secondary.button,
input.cancel.button,
input.secondary.button,
table.button.secondary button,
.delete-category span,
#widget_actions a.button,
.actions a.button,
.actions ul li a.button,
div#primary_widget_title ul#related_content li > a,
#autopay_button_container .button,
#classic_pay #payee_list #changeEBillEnrollment,
#classic_pay #payee_list #enrollEBills {
	background-color: #EEEEEE; // IE9 and below
	background-image: none;
	border: 1px solid #D0D0D0;
			box-sizing: border-box;
	color: #333333;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.50);
}

#widget_actions a.button,
.actions a.button,
.actions ul li a.button,
div#primary_widget_title ul#related_content li > a,
#autopay_button_container .button,
#classic_pay #payee_list #changeEBillEnrollment,
#classic_pay #payee_list #enrollEBills   {
	font-weight: bold;
	height: 32px;
	line-height: 16px;
	padding-top: 7px;
}

a.cancel.button:hover,
a.secondary.button:hover,
input.cancel.button:hover,
input.secondary.button:hover,
table.button.secondary button:hover,
.delete-category span:hover,
#widget_actions a.button:hover,
.actions a.button:hover,
div#primary_widget_title ul#related_content li > a:hover,
#autopay_button_container .button:hover,
#classic_pay #payee_list #changeEBillEnrollment:hover,
#classic_pay #payee_list #enrollEBills:hover   {
	background-color: #DEDEDE; // IE9 and below
	background-image: none;
	border: 1px solid #B7B5B5;
	color: #333333;
}

a.icon,
a.button.icon {
	padding-left: 35px;
	position: relative;
}

.icon:before {
	display: block;
	left: 0;
	position: absolute;
	top: 50%;
}

a.icon:before {
	height: 16px;
	margin-left: 10px;
	margin-top: -8px;
	width: 16px;
    font-family: "Alkami";
    font-size: 16px;
}

a.icon.link:before {
	content: char-from-code($font-icon-linked);
}

a.icon-add:before {
	content: char-from-code($font-icon-add);
    font-size: 16px;
    margin-top: -8px !important;
}

a.icon-replace:before {
	content: char-from-code($font-icon-retry);
}

a.icon-upload:before {
	content: char-from-code($font-icon-download);
}

a.icon-cog:before {
	content: char-from-code($font-icon-settings);
    opacity: 0.5;
    text-indent: 0 !important;

    &:hover{
        opacity: 1;
    }
}

a.icon-edit:before {
	content: char-from-code($font-icon-edit);
    text-indent: 0 !important;
}

// =============================================================================
// Everbank is currently using "icon-edit-white" in AutoDraftInformation.cshtml
// (line 42)
// =============================================================================
a.icon-edit-white:before {
	background-position: -176px -176px;
}
a.icon-edit-white:hover:before {
	background-position: -176px -156px;
}

a.icon-edit-grey:before {
	background-position: -117px -137px;
}
a.icon-edit-grey:hover:before {
	background-position: -117px -157px;
}

a.icon-info:before {
	content:  char-from-code($font-icon-info);
}

a.icon-link:before {
	content:  char-from-code($font-icon-external);
}

a.icon-loan:before {
	content: char-from-code($font-icon-u-consumer-lending);
}

// Registration buttons
.buttons-right {
    float: right;
	clear: both;
	padding-top: 10px;
	margin-top: 15px;

	a,
    input.right {
		float: right;
		margin-top: 4px;
	}

	input:last-child {
		margin-left: 6px;
	}
}

// New Window Button
.button.new-window {
    padding-right: 40px;
}

.button.new-window:after {
    background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png");
    background-position: -56px -340px !important;
    content: '';
    height: 17px;
    margin-left: 8px;
    margin-top: 1px;
    position: absolute;
    width: 23px;
}

// Button in Title Bar
div#filters div.buttons a.button,
div#filters span.buttons a.button,
div.filters div.buttons a.button,
div.title-bar div.buttons a.button,
div.filters a.add-goal.button,
div.filter-slide a.button.slide-trigger.expand {
	margin-left: 5px;
}

.input-group-btn:hover {
    cursor: pointer;
}

ul.segmented-buttons {
	display: inline-block;
	vertical-align: top;
}

.field.toggle-buttons {
	text-align: center;
}

ul.segmented-buttons > li {
	display: block;
	float: left;
	padding: 0;
}

.button {
	&.first {
		border-right: 0 none !important;
	}

	&.last {
		border-left: 0 none !important;
	}
}

ul {
	&.segmented-buttons {
		input {
			display: none;
		}

		li {
			a {
				border-radius: 0;
				font-weight: bold;
				text-align: center;
				margin: 0;
			}

			&:first-child a, a.first {
				border-radius: 3px 0 0 3px;
			}

			&:last-child a, a.last {
				border-radius: 0 3px 3px 0;
			}
		}

		a.current {
			background: #666666;
			border-color: #333333;
			box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.35) inset;
			color: #FFFFFF;
			text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
			filter: none;

			&:hover {
				background: #666666;
				border-color: #333333;
				box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.35) inset;
				color: #FFFFFF;
				text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
				filter: none;
			}
		}

		.button.middle {
			border-radius: 0;
		}
	}

	&.width-full {
		width: 100%;

		li:first-child {
			&:nth-last-child(2) {
				box-sizing: border-box;
				width: 50%;

				~ li {
					box-sizing: border-box;
					width: 50%;
				}
			}

			&:nth-last-child(3) {
				box-sizing: border-box;
				width: 33.3333%;

				~ li {
					box-sizing: border-box;
					width: 33.3333%;
				}
			}

			&:nth-last-child(4) {
				box-sizing: border-box;
				width: 25%;

				~ li {
					box-sizing: border-box;
					width: 25%;
				}
			}
		}
	}
}

.ui-state-disabled .button {
	opacity: 0.5 !important;
	filter: alpha(opacity = 50) !important;
}

div.title-bar .buttons a.button.disabled {
	background-color: rgba(0, 0, 0, 0.4) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#66000000, endColorstr=#66000000) !important;
	background-image: none !important;
	color: rgba(144, 144, 144, 0.4) !important;
	text-shadow: none !important;
}

.button.slide-trigger {
	span {
		background: url("https://assets.orb.alkamitech.com/production/assets/global/images/sprite_expand_arrows.png") no-repeat 100% 4px;
		padding: 0 10px 0 0;
		margin: 0;
	}

	&.expand span {
		background-position: 100% 4px;
	}

	&.collapse span {
		background-position: 100% -42px;
	}
}

a.list-item-button {
	background-clip: padding-box;
	border-radius: 3px;
	background-color: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
	color: #333333;
	padding: 3px 8px;
	margin: 13px 5px 0;
	background: #fff;
	border: 1px solid #D0D0D0;
	font-weight: bold;
}

/* Print Button */

a {
	&.icon-print, &.icon-download {
		display: block;
		float: right;
		height: 34px;
		overflow: hidden;
		position: relative;
		text-indent: -9999px;
		width: 34px;
	}

	&.icon-print:before, &.icon-download:before {
		background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png");
		content: " ";
		display: block;
		height: 20px;
		left: 7px;
		position: absolute;
		text-indent: 0;
		top: 7px;
		width: 20px;
	}

	&.icon-print {
		&:before {
			background-position: -57px -157px;
		}

		&:hover:before {
			background-position: -57px -177px;
		}
	}

	&.icon-download {
		&:before {
			background-position: -36px -157px;
		}

		&:hover:before {
			background-position: -36px -177px;
		}
	}
}

#modal_form button.disabled {
	opacity: .5;
	color: #fff;
}

a {
	&.help {
		padding-left: 23px;
		line-height: 15px;
	}

	&.help-icon {
		background-color: #999999;
		border-radius: 3px;
		color: white;
		display: inline-block;
		font-size: 11px;
		font-weight: bold;
		height: 14px;
		line-height: 11px;
		margin: -2px 5px 0;
		padding: 3px 1px 0px 1px;
		position: relative;
		text-align: center;
		text-decoration: none;
		top: -1px;
		width: 16px;
		z-index: 9999;

		&:hover {
			color: white;
			background-color: rgba(51, 51, 51, 0.85);
		}
	}
}
