// Related Content Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base related content styles
//
//
// Widget Action Buttons
// =============================================================================

#widget_actions {
   position: absolute;
   top: 22px;
   right: 15px;
   display: flex;
}

.widget-action-item {
   margin-right: 8px;
   flex-grow: 0;

   &:last-child {
      margin-right: 0;
   }

   .widget-action__button {
      padding: 8px;

      .iris-button__icon:before {
         font-size: 20px;
      }
   }
}
// TODO: Content Article Related styles that need to go away!

#widget_actions > .actions {
   margin-right: 8px;
   display: flex;
   
   & > .iris-button {
      padding: 8px;
      
      .iris-button__icon:before {
         font-size: 20px;
      }
   } 
   
   &:last-child {
      margin-right: 0;
   }

   & > .button {
      padding: 8px;
      height: auto;
      line-height: 20px;
      background-color: #fff;
      border-color: #707070;
      color: #707070;
      font-weight: 400;
      font-size: 1.4rem !important;

      & + .button {
         margin-left: 8px;
      }
   }

   & > .button.icon {
      padding-left: 33px;

      &:before {
         line-height: 20px;
         font-size: 20px;
         margin-top: -10px !important;
         margin-left: 8px;
         height: 20px;
         width: 20px;
      }
   }
}

#related_content {
   display: none;
}

.legacy-action-item {
   .actions ul.dropdown-list {
      top: 46px;
   }

   .button.disabled,
   .ui-state-disabled .button {
      cursor: default;
   }

   #filters .button,
   .button,
   .filters .button,
   .ui-button,
   .ui-dialog .ui-dialog-buttonpane button,
   .x-window-plain .button button,
   input[type=submit] {
      background-clip: padding-box;
      border-radius: 3px;
   }

   .excerpt {
      display: none;
      position: absolute;
      z-index: 1000;
      background: url("https://assets.orb.alkamitech.com/production/assets/global/images/white_arrow_big.png") no-repeat;
      width: 260px;
      height: 135px;
      padding: 40px 20px 0;
      text-align: center;
   }

   .list-item.disabled span.holder {
      background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/forms_checkboxes.png") !important;
      background-position: 0 0 !important;
      border: 0 none;
      height: 22px !important;
      left: 0;
      width: 22px !important;
      top: 0;
   }

   span.new {
      border-radius: 3px;
      height: 16px;
      left: -8px;
      top: -7px;
      width: 16px;
   }

   & > .iris-button--action .new {
      border-radius: 0 2px 0 8px;
      color: #FFFFFF;
      left: auto;
      height: 11px;
      padding: 4px;
      right: -1px;
      text-align: center;
      top: -1px;
      width: 11px;

      em {
         color: #FFFFFF;
         display: inline;
         font-size: 11px;
         font-style: normal;
         font-weight: bold;
         left: auto;
         line-height: 10px;
         position: static;
         text-align: center;
         top: auto;
      }
   }
}