﻿// X-Window Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base x-window styles

.x-window-plain.x-plain {
	td.x-toolbar-right table.x-btn {
		display: table;
		padding: 0;
	}

	.x-tool.x-tool-close {
		background: url("https://assets.orb.alkamitech.com/production/assets/global/images/vendor/extjs/default/window/icon-close.png") no-repeat;
	}
}

.x-window-plain {
	&.x-plain, &.x-window-dlg {
		box-shadow: 0px 0px 5px #666666;
		overflow: hidden;
		background: #ffffff;
		color: #333333;
		border: 1px solid #D0D0D0;
		padding: 6px 10px;
	}

	&.x-plain .x-plain-header-text {
		font-size: 18px;
		text-shadow: 0 1px 1px #fff;
		font-weight: bold;
		display: block;
		padding: 9px 0 14px;

		h3 {
			font-size: 18px;
			text-shadow: 0 1px 1px #fff;
			font-weight: bold;
			display: block;
			padding: 9px 0 14px;
		}
	}
}

.x-window-dlg .x-window-header-text {
	font-size: 18px;
	text-shadow: 0 1px 1px #fff;
	font-weight: bold;
	display: block;
	padding: 9px 0 14px;
}

.x-window-plain.x-plain td.x-toolbar-right table.x-btn {
	background: transparent;
	border: 0;
}

.x-toolbar-right-row .back-button {
	width: 470px;
	text-align: left;
}

.x-window-plain {
	&.x-plain td.x-toolbar-left {
		display: none;

		> table {
			display: none;
		}
	}

	.x-btn-tl, .x-btn-tr, .x-btn-tc, .x-btn-ml, .x-btn-mr, .x-btn-mc, .x-btn-bl, .x-btn-br, .x-btn-bc, .x-window-tl, .x-window-tc, .x-window-tr, .x-window-ml, .x-window-mc, .x-window-mr, .x-window-bl, .x-window-bc, .x-window-br {
		background: transparent !important;
	}
}

.x-window-dlg .x-tool-close {
	visibility: hidden;
}