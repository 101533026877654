﻿// Placeholder Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base placeholder styles

// Input Placeholders
::-webkit-input-placeholder {
    color: $input-gray;
 }
 
 :-moz-placeholder { // Firefox 18-
    color: $input-gray;
 }
 
 ::-moz-placeholder {  // Firefox 19+
    color: $input-gray;
 }
 
 :-ms-input-placeholder {
    color: $input-gray;
 }

input::placeholder {
  user-select: none;
}