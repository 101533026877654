﻿// Primary Nav Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base primary nav styles

#primary_nav {
  background: transparent;
  padding: 0;
  z-index: 800;
  min-width: 105px;

  nav#favorite_widgets.widget-navigation-list__favorite-widgets {
      height: 100% !important;
  }

  a {
    position: relative;
    margin: 10px 0;
    background-color: rgba(0, 0, 0, 0.39);
    background-clip: padding-box;
    border-radius: 3px;

    span {
      font-size: 11px;
      visibility: hidden;
      display: block;
      text-align: center;
      padding-top: 26px;
      width: 53px;
      margin: 0 auto;
      position: relative;
      z-index: 2;
    }
  }

	nav {
		margin-top: 0;

		a {
			margin: 0 0 10px 0;
		}
	}

	// Remove hover overlay
	a:hover span.overlay,
    a:focus span.overlay {
		display: none;
	}
}

#primary_widget.selection-view #primary_widget_title {
	margin: 0;
	padding: 20px 15px 0;
}

// Favorite Widgets
// =============================================================================
nav#favorite_widgets {
	opacity: 0;
	transition: opacity linear 0.4s;

	&.appear {
		opacity: 1.0;
  }

  a.current {
    background-clip: padding-box;
    border-radius: 3px;
  }
}

div#primary_nav nav#favorite_widgets a,
div#primary_nav nav#favorite_widgets a.current {
    display: block;
    height: auto;
    max-height: 90px;
    padding: 15px 5px;
    transition: all 0.2s ease;
    text-align: center;
    text-decoration: none;
    width: 85px;
    box-sizing: border-box;
}

div#primary_nav nav#favorite_widgets a:focus {
  transition: none;
}

div#primary_nav nav#favorite_widgets a:before {
    display: block;
    font-family: "Alkami";
    font-size: 30px;
    height: auto;
    line-height: 32px;
    margin: 0 auto 2px;
	width: 41px;
    font-weight: normal;
}

div#primary_nav nav#favorite_widgets a,
div#primary_nav div#more_widgets nav#more_widgets_nav a {
  &:hover,
  &:focus {
  	background-clip: padding-box;
  	border: 0 none;
  	border-radius: 3px;
  }
}

// Override default widget nav styling on spans for Shave truncation plugin
#primary_nav,
div#primary_nav div#more_widgets nav#more_widgets_nav a:hover,
div#primary_nav div#more_widgets nav#more_widgets_nav a:focus {
  .js-shave-char {
    width: auto;
    line-height: 1 !important;
    padding-top: 0 !important;
  }
}

div#primary_nav a span,
div#primary_nav div#more_widgets nav#more_widgets_nav a:hover span,
div#primary_nav div#more_widgets nav#more_widgets_nav a:focus span {
	background: transparent;
  display: inline-block;
	font-weight: bold;
	font-size: 9px;
  line-height: 12px;
	padding-top: 4px !important;
	text-transform: uppercase;
  transition: height 1s ease-in-out;
	visibility: visible;
  width: 75px;
}

div#primary_nav a span.truncate,
div#primary_nav div#more_widgets nav#more_widgets_nav a:hover span.truncate,
div#primary_nav div#more_widgets nav#more_widgets_nav a:focus span.truncate {
    overflow: hidden;
    padding-top: 0 !important;
    text-overflow: ellipsis;
    white-space: nowrap;

}

.widget-relative {
    position: relative;
}

// More Widgets
// =============================================================================
div#primary_nav {
    #favorite_widgets #more_icon {
        height: 85px !important;
    }
    div#more_widgets {
      min-width: 850px;
      position: absolute;
      z-index: 9999;
      left: 95px;
      top: 0;

        #more_widgets_nav {
          background-clip: padding-box;
          border-radius: 3px;
          background-color: rgba(0, 0, 0, 0.39);
          float: left;

          a {
            display: block;
            float: left;
            background-color: transparent;
            margin: 0;
            display: block;
            width: 85px;
            color: #fff;
            height: 85px;

            &:hover span {
              background-clip: padding-box;
              border-radius: 3px;
              background-color: rgba(0, 0, 0, 0.3);
              display: block;
              width: 66px;
              height: 37px;
            }
          }
        }
    }
}

div#primary_nav div#more_widgets nav#more_widgets_nav {
  box-shadow: 0 1px 3px rgba(100, 100, 100, 0.65);
  box-sizing: border-box;
}

// Widget Icon Fonts
// =============================================================================
#favorite_widgets {
  [class*="font-icon-"]:before {
    font-size: 30px;
  }
}

ul#widget_nav {
	border-bottom: 1px solid #CCCCCC;
	clear: both;
	display: block;
	height: 43px;
	padding-left: 15px;
	padding-top: 15px;
	margin-left: -15px;
	margin-right: -15px;

	li {
		display: block;
		float: left;
		font-weight: bold;
		margin: 0;

		&:first-child a {
			border-left: 1px solid #CCCCCC;
		}

		a {
			background-color: #EEEEEE;
			border: 1px solid #D0D0D0;
			border-left: 0 none;
			box-sizing: border-box;
			color: #707070;
			display: inline-block;
			height: 44px;
			padding: 13px 15px 12px;
			text-decoration: none;
		}
	}

	a.current {
		background-color: #FFFFFF;
		border-top: 3px solid #595959;
		border-bottom: 0 none;
		color: #333333;
		font-weight: bold;
		text-shadow: none;
		top: 0;
	}
}

ul#progress_nav li {
	span.line, .step {
		background-image: none;
		background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.1) 100%, rgba(0, 0, 0, 0.3) 100%);
	}
}
