﻿// Table Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base table styles

table.data {
	margin-bottom: 10px;
	color: #333333;
	width: 100%;
	border-collapse: collapse;

	thead tr {
		background: #dedede;
		border: 1px solid #D1D2D4;
	}

	tbody tr {
		background-color: #f7f8f9;
		color: #333333;
		border: 1px solid #D1D2D4;

		&:hover {
			background: #FFF;
		}
	}

	th {
		padding: 5px 10px;
		font-weight: bold;
		text-shadow: 0 -1px #eeeeee;

		&.nickname {
			width: 270px;
		}

		&.contact {
			width: 300px;
		}
	}

	td {
		padding: 0 10px;
		height: 30px;

		a {
			margin-right: 5px;
		}

		&.row-actions a {
			background-clip: padding-box;
			border-radius: 3px;
			float: right;
			color: #333333;
			padding: 3px 8px;
			margin: 6px 0 6px 10px;
			background: #fff;
			border: 1px solid #D0D0D0;
			font-weight: bold;
		}
	}
}