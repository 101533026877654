﻿// Header Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base header styles

div#header {
	border-radius: 0;
	float: none;
	height: 106px;
	margin: 0;
	padding: 0;
	width: 100%;

	a#header_logo {
		display: block;
		width: 240px;
		height: 100px;
		float: left;
	}
}

div#header_container {
	height: 106px;
	margin: 0 auto;
	padding: 0;
	width: 1005px;
}

div#header h1 {
	background-color: transparent;
	border: 0 none;
	box-shadow: none;
	height: 100px;
}

div#promo {
    border-radius: 3px;
    height: 75px;
    margin: 14px 0 0 10px;
    overflow: hidden;
	width: 490px;
	float: left;
}

div#promo a {
    display: block;
    height: 75px;
    margin: 0;
    width: 490px;
}

div#promo a img {
    border-radius: 3px;
    width: 490px;
    height: 75px;
}