﻿// Base Generic Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base generic styles

html {
    font-size: 62.5%;
    height: 100%;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-size: cover;
}

body {
	font-size: 12px;
	color: #333333;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
	min-width: fit-content;
}

a {
	text-decoration: none;
}

p {
	line-height: 125%;
}

em {
	font-style: italic;
}

hr {
	clear: both;
	border: none;
	height: 1px;
	padding: 0;
	background: #eee;
}

h6 {
	color: #333333;
	font-weight: bold;
}

strong {
	font-weight: bold;
}

label {
	text-transform: capitalize;

	&.no-title {
		text-transform: none;
	}
}

h4 + ol {
	margin-top: 10px;
}

body.debug {
	&:before,
	&:after {
		bottom: 0;
		color: #CED0D4;
		font-size: 10px;
		left: 0;
		pointer-events: none;
		position: fixed;
		z-index: 999999999;
	}

	&:before {
		background: #000;
		border-radius: 0 3px 0 0;
		content: "DEBUG MODE";
		opacity: .8;
		padding: 6px 6px 6px 22px;
	}

	&:after {
		content: char-from-code($font-icon-developer);
		font-family: 'Alkami';
		opacity: .6;
		padding: 6px;
	}
}
