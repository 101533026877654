﻿/**
* Inspired by tooltip.css:
* http://kushagragour.in/lab/tooltip/
*/

/**
 * Tooltip options (the first option is the default one):
 * 		size: <none>, small, medium, large
 * 		style: <dark>, light, error
 * 		position: <pos-top>, pos-right, pos-bottom, pos-left
 * 		anim: <fade>, anim-none, anim-bounce
 * 		anchor: <none>, anchor-right, anchor-left, anchor-top, anchor-bottom | anchor-center to center the arrow
 * 		focus: <enabled>, no-focus
 *		text-align: <align-center>, align-left, align-right
 */

// Colors
$background-dark: #333;
$background-light: #fff;
$text-dark: #333;
$text-light: #fff;

$default-bg: $background-dark;
$default-text: $text-light;

$light-bg: $background-light;
$light-text: $text-dark;

$error-bg: #b34e4d;
$error-text: $text-light;

/* Base markup */
[data-tooltip]:not([data-tooltip=""]) {
	display: inline-block;
	position: relative;

	&:before,
	&:after {
		position: absolute;
		transform: translate3d(0, 0, 0);
		visibility: hidden;
		opacity: 0;
		text-indent: 0;
		z-index: 1000000;
		pointer-events: none;
		transition-delay: 0ms;
	}

	&:hover:before,
	&:hover:after,
	&:not([data-tooltip-options~="no-focus"]):focus:before,
	&:not([data-tooltip-options~="no-focus"]):focus:after {
		opacity: 1;
		visibility: visible;
	}

	&:hover:before,
	&:hover:after {
		transition-delay: 100ms;
	}

	&:before {
		background: transparent;
		border: 6px solid transparent;
		content: '';
		position: absolute;
		z-index: 1000001;
	}

	&:after {
		background: $default-bg;
		border-radius: 3px;
		box-sizing: border-box;
		color: $default-text;
		content: attr(data-tooltip);
		font-size: 12px;
		font-weight: 400;
		line-height: 12px;
		padding: 10px 10px;
		white-space: nowrap;
	}


}

// Change tooltip text when in off state
.off [data-tooltip][data-tooltip-off]:after {
	content: attr(data-tooltip-off);
}

/* Anchoring */
[data-tooltip][data-tooltip-options~="anchor-right"] {
	&:not([data-tooltip-options~="anchor-center"]):before {
		left: auto !important;
		right: 10px !important;
	}

	&:after {
		left: auto !important;
		margin-right: 0 !important;
		right: 0 !important;
	}
}

[data-tooltip][data-tooltip-options~="anchor-left"] {
	&:not([data-tooltip-options~="anchor-center"]):before {
		left: 10px !important;
		right: auto !important;
	}

	&:after {
		left: 0 !important;
		margin-left: 0 !important;
		right: auto !important;
	}
}

[data-tooltip][data-tooltip-options~="anchor-top"] {
	&:not([data-tooltip-options~="anchor-center"]):before {
		bottom: auto !important;
		top: 10px !important;
	}

	&:after {
		bottom: auto !important;
		margin-top: 0 !important;
		top: 0 !important;
	}
}

[data-tooltip][data-tooltip-options~="anchor-bottom"] {
	&:not([data-tooltip-options~="anchor-center"]):before {
		bottom: 10px !important;
		top: auto !important;
	}

	&:after {
		bottom: 0 !important;
		margin-bottom: 0 !important;
		top: auto !important;
	}
}

/* Text alignment */
[data-tooltip-options~="align-right"]:after {
	line-height: 16px;
	text-align: right !important;
	white-space: normal;
}

[data-tooltip-options~="align-left"]:after {
	line-height: 16px;
	text-align: left !important;
	white-space: normal;
}

/* Tooltip size */
[data-tooltip]:not([data-tooltip-options~="pos-bottom"]):not([data-tooltip-options~="pos-right"]):not([data-tooltip-options~="pos-left"]),
[data-tooltip] {
	&[data-tooltip-options~="small"]:after,
	&[data-tooltip-options~="medium"]:after,
	&[data-tooltip-options~="large"]:after {
		line-height: 16px;
		text-align: center;
		white-space: normal;
	}

	&[data-tooltip-options~="small"]:after {
		margin-left: -50px;
		width: 100px;
	}

	&[data-tooltip-options~="medium"]:after {
		margin-left: -125px;
		width: 250px;
	}

	&[data-tooltip-options~="large"]:after {
		margin-left: -200px;
		width: 400px;
	}
}

/* Default/top tooltip */
[data-tooltip]:not([data-tooltip-options~="pos-bottom"]):not([data-tooltip-options~="pos-right"]):not([data-tooltip-options~="pos-left"]),
[data-tooltip-options="pos-top"] {
	&:before {
		border-top-color: $default-bg;
		// Since the callout is 12px wide, we offset it by 6px to center it
		margin-left: -6px;
		margin-bottom: -11px;
	}

	&:after {
		margin-left: -18px;
	}

	&:before,
	&:after {
		bottom: 100%;
		left: 50%;
	}

	&:hover:after,
	&:hover:before,
	&:focus:after,
	&:focus:before {
		transform: translateY(-10px);
	}
}

/* Bottom tooltip */
[data-tooltip-options~="pos-bottom"]:not([data-tooltip=""]) {
	&:before {
		border-bottom-color: $default-bg;
		margin-left: -6px;
		margin-top: -11px;
	}

	&:after {
		margin-left: -18px;
	}

	&:before,
	&:after {
		top: 100%;
		left: 50%;
	}

	&:hover:after,
	&:hover:before,
	&:focus:after,
	&:focus:before {
		transform: translateY(10px);
	}
}

/* Right tooltip */
[data-tooltip-options~="pos-right"]:not([data-tooltip=""]) {
	&:before {
		border-right-color: $default-bg;
		margin-bottom: -6px;
		margin-left: -11px;
	}

	&:after {
		margin-bottom: -14px;
	}

	&:before,
	&:after {
		bottom: 50%;
		left: 100%;
	}

	&:hover:after,
	&:hover:before,
	&:focus:after,
	&:focus:before {
		transform: translateX(10px);
	}
}

/* Left tooltip */
[data-tooltip-options~="pos-left"]:not([data-tooltip=""]) {
	&:before {
		border-left-color: $default-bg;
		margin-bottom: -6px;
		margin-right: -11px;
	}

	&:after {
		margin-bottom: -14px;
	}

	&:before,
	&:after {
		bottom: 50%;
		right: 100%;
	}

	&:hover:after,
	&:hover:before,
	&:focus:after,
	&:focus:before {
		transform: translateX(-10px);
	}
}

/* Light style */
[data-tooltip-options~="light"]:not([data-tooltip=""]) {
	&:after {
		background-color: $light-bg !important;
		box-shadow: 0 2px 10px rgba(0, 0, 0, .25);
		color: $light-text;
	}

	&[data-tooltip]:not([data-tooltip-options~="pos-bottom"]):not([data-tooltip-options~="pos-right"]):not([data-tooltip-options~="pos-left"]):before,
	&[data-tooltip-options="pos-top"]:before {
		border-top-color: $light-bg;
	}

	&[data-tooltip-options~="pos-bottom"]:before {
		border-bottom-color: $light-bg;
	}

	&[data-tooltip-options~="pos-left"]:before {
		border-left-color: $light-bg;
	}

	&[data-tooltip-options~="pos-right"]:before {
		border-right-color: $light-bg;
	}
}

/* Error style */
[data-tooltip-options~="error"]:not([data-tooltip=""]) {
	&:after {
		background-color: $error-bg;
		color: $error-text;
	}

	&[data-tooltip]:not([data-tooltip-options~="pos-bottom"]):not([data-tooltip-options~="pos-right"]):not([data-tooltip-options~="pos-left"]):before,
	&[data-tooltip-options="pos-top"]:before {
		border-top-color: $error-bg;
	}

	&[data-tooltip-options~="pos-bottom"]:before {
		border-bottom-color: $error-bg;
	}

	&[data-tooltip-options~="pos-left"]:before {
		border-left-color: $error-bg;
	}

	&[data-tooltip-options~="pos-right"]:before {
		border-right-color: $error-bg;
	}
}

/* Always visible */
[data-tooltip-options~="static"]:not([data-tooltip=""]) {
	&:after, &:before {
		opacity: 1;
		visibility: visible;
		transition-duration: 0ms;
	}

	&[data-tooltip]:not([data-tooltip-options~="pos-bottom"]):not([data-tooltip-options~="pos-right"]):not([data-tooltip-options~="pos-left"]):before,
	&[data-tooltip]:not([data-tooltip-options~="pos-bottom"]):not([data-tooltip-options~="pos-right"]):not([data-tooltip-options~="pos-left"]):after,
	&[data-tooltip-options="pos-top"]:before,
	&[data-tooltip-options="pos-top"]:after {
		transform: translateY(-10px);
	}

	&[data-tooltip-options~="pos-bottom"]:after,
	&[data-tooltip-options~="pos-bottom"]:before {
		transform: translateY(10px);
	}

	&[data-tooltip-options~="pos-left"]:after,
	&[data-tooltip-options~="pos-left"]:before {
		transform: translateX(-10px);
	}

	&[data-tooltip-options~="pos-right"]:after,
	&[data-tooltip-options~="pos-right"]:before {
		transform: translateX(10px);
	}
}

/**
 * Transition effects. The default is a fade-in.
 * - anim-none
 * - anim-bounce
 */
[data-tooltip]:before,
[data-tooltip]:after {
	transition: 0.3s ease;
}

[data-tooltip-options~="anim-none"]:before,
[data-tooltip-options~="anim-none"]:after {
	transition-duration: 0ms;
}

[data-tooltip-options~="anim-bounce"]:before,
[data-tooltip-options~="anim-bounce"]:after {
	transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
}

// Anchored left a checkbox
[data-tooltip][data-tooltip-options~="anchor-left"][data-tooltip-options~="checkbox"] {
	&:not([data-tooltip-options~="anchor-center"]):before {
		left: -2px !important;
	}
 }

// Anchored left on a radio button
[data-tooltip][data-tooltip-options~="anchor-left"][data-tooltip-options~="radio"] {
	&:not([data-tooltip-options~="anchor-center"]):before {
		left: 13px !important;
	}
}
