﻿// Tooltip Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base tooltip styles

// Floating Check Image Styling
// =============================================================================

// Pertains to AccountsV2, and BillPay
.image-tooltip-wrapper {
    display: none;
    margin-top: 0;
    padding-left: 15px;
    position: absolute;
}

.image-tooltip-ct {
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 5px;
    height: 98px;
    width: auto !important;
 }

// Content Tooltip
// =============================================================================
.content-tooltip-wrapper {
    background: url("https://assets.orb.alkamitech.com/production/assets/global/images/bg_tooltip_arrow.png") no-repeat 93% 0;
    display: none;
    font-weight: normal;
    left: -291px;
    padding-top: 15px;
    position: absolute;
    top: 25px;
    width: 350px;
    z-index: 9010;
  }

  
.content-tooltip-ct {
	background-clip: padding-box;
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0.5);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#7F000000, endColorstr=#7F000000);
	padding: 5px;
}

.content-tooltip-no-content {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    text-align: center;
    display: inline-block;
    width: 100%;
    margin-top: 20px
}

.content-tooltip-content {
	background-clip: padding-box;
	border-radius: 3px;
	background: #ffffff;
	overflow: hidden;
	width: 100%;

	.articles {
		padding: 0 10px 10px;
	}

	.button {
		float: right;
		margin: 15px 5px 10px 0;
	}

	.article {
		font-size: 11px;

		hr {
			margin: 6px 0 0;
			width: 100%;
		}

		&:first-child hr {
			display: none;
		}

		span.date {
			color: #707070;
			display: block;
			margin: 2px 0 5px;
		}

		.content-body + a {
			display: block;
			margin-top: 5px;
		}
	}

	h3 a {
		font-size: 12px;
		font-weight: bold;
		color: #333333;
		display: block;
		margin-top: 7px;
	}
}

.tooltip-wrapper {
	display: block;
	padding-top: 6px;
	position: absolute;
	z-index: 10000;

	&:before {
		border-bottom: 6px solid #333;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		content: '';
		position: absolute;
		top: 1px;
		right: 50%;
		margin-right: -6px;
	}
}

.tooltip-container {
	background: #333;
	border-radius: 3px;
	box-sizing: border-box;
	color: #fff;
	font-size: 12px;
	font-weight: 400;
}

.tooltip-content {
	padding: 10px;
	max-width: 160px;
	box-sizing: border-box;
}
