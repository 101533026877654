// Base Link Styles
// Included in every theme via the _base.scss file
//
// This partial setsup the base theme styles for links


// Variables
$link-color: #4371AD !default;
$link-hover-color: darken(#4371AD, 10%);


a,
.dash-mod.mod .mod-content a.external{
    color: $link-color;
    text-decoration: none;
}

a:hover,
a:focus,
.dash-mod.mod .mod-content a.external:hover,
 dash-mod.mod .mod-content a.external:focus {
	color: $link-hover-color;
    cursor: pointer;
}

// Override hover effect on account selector
a.list-item:hover,
a.list-item:focus {
	color: #707070;
}

a.interstitial-interaction,
a.instance-interaction {
    margin: 5px;
}
a.interstitial-interaction > img,
a.instance-interaction > img {
    margin: -5px -5px 0 -5px;
}

a.icon-after:after {
	background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png");
	content: " ";
	height: 22px;
	position: absolute;
	width: 22px;
}
