﻿// Tab Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base tab styles

ul.x-tab-strip-bottom {
	background-color: transparent;
	background-image: none;
	border: 0;

	span.x-tab-strip-text {
		font-weight: bold;
		font-style: normal;
		color: #b5b5b5;
		text-transform: uppercase;
		font-size: 11px;
		line-height: 25px;
		padding: 0;
	}

	li {
		margin-left: 0;

		&.x-tab-strip-over span.x-tab-strip-text, &.x-tab-strip-active span.x-tab-strip-text {
			color: #333333;
			line-height: 25px;
			padding: 0;
		}

		a.x-tab-right, &.x-tab-strip-over a.x-tab-right {
			background: transparent;
			border: 1px solid transparent;
			border-top: 0;
		}

		a.x-tab-right em.x-tab-left {
			background: transparent;
		}

		&.x-tab-strip-active {
			.x-tab-right {
				span.x-tab-strip-text {
					padding-top: 0;
				}

				background: #FFF;
				border-color: #D0D0D0;
			}

			&.x-tab-strip-over .x-tab-right {
				background: #FFF;
				border-color: #D0D0D0;
			}
		}
	}
}
