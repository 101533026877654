﻿// Base Empty Styles
// Included in every theme via the _base.scss file
//
// This partial sets up the styles for our empty pages etc

// Empty Page Styling
// =============================================================================
div.empty {
    color: #707070;
    padding: 20px 10px;
    text-align: center;

  // This adds borders to the top and bottom of the "empty" div
	&.empty-row {
		border-top: 1px solid #e5e5e5;
		border-bottom: 1px solid #e5e5e5;

		&.short {
			margin: 30px 0;
		}
		&.tall {
			margin: 50px 0;
		}
		&.pyre {
			padding: 50px 0;
		}
	}

	p,
	.title {
		font-size: 16px;
		line-height: 150%;
		margin: 20px 0;
		text-align: center;

		span {
			display: block;
			font-size: 13px;
			margin-top: 10px;
		}
	}

	a,
	input {
		margin-bottom: 20px;
	}

  // Adds a subtle background on the section and title / p elements
	&.subtle {
		background: #FAFAFB;

		.title {
			color: $light-gray;
			font-weight: 700;
			margin-bottom: 10px;

			+ p {
				color: $light-gray;
				margin-top: 10px;
			}
		}

		p {
			font-size: 13px;
		}

		a,
		input {
			margin: 15px 0 20px;
		}
	}
}

// Interstitial Landing Page
// =============================================================================
#primary_widget_content .interstitial {
	background-color: #f7f7f7;
	border: 1px solid #D0D0D0;
	border-radius: 5px;
	color: #333333;
	margin: 50px auto;
	padding: 20px 0 0;
	text-align: center;
	width: 45%;

	p {
		font-size: 16px;
		line-height: 150%;
		margin: 20px 0 40px;
		text-align: center;
	}

	input[type="text"] {
		display: block;
		float: none;
		font-size: 16px;
		margin: 0 auto 40px;
		text-align: center;
		width: 30%;
	}

	.footer {
		background-color: #fff;
		border-top: 1px solid #e5e5e5;
		border-radius: 0 0 5px 5px;
		padding: 25px;

		.button {
			margin: 0;
			width: 100%;
		}
	}

	a {
		margin-bottom: 20px;
	}
}

// Fusion Widget - Blank Slate
// =============================================================================
.blank-slate {
    background: #f8f8f8;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    box-shadow: 0 0 15px 2px rgb(228,228,228) inset;
    margin: 20px auto 50px;
    padding: 25px;
    width: 55%;
}
#primary_widget_content .blank-slate h3 {
	color: #333333;
	font-size: 15px;
	line-height: 135%;
	margin-bottom: 30px;
}
.blank-slate p {
	color: #333333;
	font-size: 13px;
	line-height: 150%;
	margin-bottom: 20px;
}
.blank-slate p:last-child {
	margin-bottom: 0;
}
.blank-slate p a {
	display: block;
}
.blank-slate ul,
.blank-slate ol {
	padding-left: 15px;
}
.blank-slate ul li,
.blank-slate ol li {
	padding-bottom: 20px;
	line-height: 140%;
}
.blank-slate ul li {
	list-style: disc;
}
.blank-slate ol li {
	list-style: decimal;
}
.blank-slate li:last-child {
	padding-bottom: 0;
}
.blank-slate .blank-slate-tall {
	margin: 45px 0;
}
	
/* Blank Slate Message */

div {
	&.blank-slate-message {
		border: 2px dashed #D0D0D0;
		border-radius: 5px 5px 5px 5px;
		padding: 30px;
	}

	&#primary_widget_content div.blank-slate-message {
		h3, p {
			color: #333333;
			line-height: 150%;
			margin-bottom: 10px;
		}

		h3 {
			font-size: 15px;
		}

		p {
			margin-bottom: 20px;
		}
	}

	&.message.info {
		background: url("https://assets.orb.alkamitech.com/production/assets/global/images/icon_info.png") 15px 17px no-repeat;
		border: solid 2px #C4BD9F;
		border-radius: 2px;
		vertical-align: middle;
		text-align: left;
		padding: 15px;
		padding-left: 40px;
		margin-bottom: 20px;

		p {
			margin: 0 0 12px;

			&:last-child {
				margin: 0 !important;
			}
		}
	}
}