// Font icon selector classes
[class*="font-icon-"] {
	line-height: 1;
}


[class*="font-icon-"]:before,
[class*="font-icon-"]:after, {
	display: inline-block;
	font-family: 'Alkami';
	font-size: 16px;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	speak: none;
	text-transform: none;
	vertical-align: middle;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class*="font-icon-"] {
	&[data-icon-size="xs"] {
		&:before,
		&:after {
			font-size: 1.6rem;
		}
	}
	
	&[data-icon-size="sm"] {
		&:before,
		&:after {
			font-size: 2.0rem;
		}
	}

	&[data-icon-size="md"] {
		&:before,
		&:after {
			font-size: 2.4rem;
		}
	}

	&[data-icon-size="lg"] {
		&:before,
		&:after {
			font-size: 2.8rem;
		}
	}

	&[data-icon-size="xl"] {
		&:before,
		&:after {
			font-size: 3.2rem;
		}
	}

	&[data-icon-size="2xl"] {
		&:before,
		&:after {
			font-size: 4.2rem;
		}
	}

	&[data-icon-size="3xl"] {
		&:before,
		&:after {
			font-size: 6.4rem;
		}
	}

	&[data-icon-size="4xl"] {
		&:before,
		&:after {
			font-size: 7.2rem;
		}
	}
}

// Font icon global overrides
.font-icon-star-filled {
	color: #D7A300;
}



// Generated from our GULP task generateIconFonts icon font

.font-icon-business {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA28';
		
    }
}

.font-icon-handshake {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA86';
		
    }
}

.font-icon-linked {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA96';
		
    }
}

.font-icon-shared {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAF5';
		
    }
}

.font-icon-applications {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA18';
		
    }
}

.font-icon-article {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA1B';
		
    }
}

.font-icon-bookmark {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA22';
		
    }
}

.font-icon-csv {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA04';
		
    }
}

.font-icon-document {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA59';
		
    }
}

.font-icon-education {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA64';
		
    }
}

.font-icon-file-tasks-dollar {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA76';
		
    }
}

.font-icon-file-tasks-edit {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA77';
		
    }
}

.font-icon-forms-and-services {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA7B';
		
    }
}

.font-icon-generic-icon-book {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA7D';
		
    }
}

.font-icon-generic-icon-clipboard {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA7E';
		
    }
}

.font-icon-list {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA99';
		
    }
}

.font-icon-note {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAA8';
		
    }
}

.font-icon-memo {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAA8';
		
    }
}

.font-icon-newspaper {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAB4';
		
    }
}

.font-icon-pdf {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA05';
		
    }
}

.font-icon-quiz {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAE4';
		
    }
}

.font-icon-reports {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA06';
		
    }
}

.font-icon-section {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAEF';
		
    }
}

.font-icon-calendar {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA2C';
		
    }
}

.font-icon-paymentduesoon {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EABF';
		
    }
}

.font-icon-paymentoverdue {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAC0';
		
    }
}

.font-icon-reccurringtransfer {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAE7';
		
    }
}

.font-icon-recurring {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAE7';
		
    }
}

.font-icon-recurringtransfer {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAE8';
		
    }
}

.font-icon-chat-lined {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA38';
		
    }
}

.font-icon-chat {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA38';
		
    }
}

.font-icon-chat-filled {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA38';
		
    }
}

.font-icon-chat-line {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA39';
		
    }
}

.font-icon-dispute {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA58';
		
    }
}

.font-icon-double-chat {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA5C';
		
    }
}

.font-icon-ebill {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA60';
		
    }
}

.font-icon-email-at {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA69';
		
    }
}

.font-icon-incoming-receive-call {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB15';
		
    }
}

.font-icon-notification-solid {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAB8';
		
    }
}

.font-icon-notification {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAB9';
		
    }
}

.font-icon-push-notifications {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EABA';
		
    }
}

.font-icon-notifications {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EABA';
		
    }
}

.font-icon-phone-outgoing {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EACA';
		
    }
}

.font-icon-phone {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EACB';
		
    }
}

.font-icon-push-notification {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAD3';
		
    }
}

.font-icon-receive-call {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAE6';
		
    }
}

.font-icon-atm_locator {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA1D';
		
    }
}

.font-icon-authorization-requests {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA1F';
		
    }
}

.font-icon-bulls-and-bears {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA25';
		
    }
}

.font-icon-business-setup {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA27';
		
    }
}

.font-icon-card-manage-pay {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA32';
		
    }
}

.font-icon-cashback {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA37';
		
    }
}

.font-icon-credit-card {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA4F';
		
    }
}

.font-icon-direct-deposit {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA57';
		
    }
}

.font-icon-dollar-circle {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA5A';
		
    }
}

.font-icon-dollar {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA5B';
		
    }
}

.font-icon-external {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA6E';
		
    }
}

.font-icon-fees-charges {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA73';
		
    }
}

.font-icon-fi-simple {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA75';
		
    }
}

.font-icon-fi {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA75';
		
    }
}

.font-icon-fico {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA02';
		
    }
}

.font-icon-generic-icon-coins {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA7F';
		
    }
}

.font-icon-generic-icon-eisle {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA80';
		
    }
}

.font-icon-generic-icon-money {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA82';
		
    }
}

.font-icon-online-deposits {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EABB';
		
    }
}

.font-icon-open-accounts {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EABC';
		
    }
}

.font-icon-pending-external-accounts {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAC3';
		
    }
}

.font-icon-percent-circle {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAC6';
		
    }
}

.font-icon-positive-pay {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EACF';
		
    }
}

.font-icon-rates {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAE5';
		
    }
}

.font-icon-savvy-money {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAED';
		
    }
}

.font-icon-security-chip {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAF0';
		
    }
}

.font-icon-send-money {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAF2';
		
    }
}

.font-icon-tag {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB00';
		
    }
}

.font-icon-authentication {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA1E';
		
    }
}

.font-icon-budget-tools {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA23';
		
    }
}

.font-icon-calculator {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA2B';
		
    }
}

.font-icon-cash-edge {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA36';
		
    }
}

.font-icon-desktop {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA55';
		
    }
}

.font-icon-developer {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA56';
		
    }
}

.font-icon-full-site {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA7C';
		
    }
}

.font-icon-globe {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA84';
		
    }
}

.font-icon-keychain {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA94';
		
    }
}

.font-icon-lock-token {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAA0';
		
    }
}

.font-icon-lock {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAA1';
		
    }
}

.font-icon-mobile-settings {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAAA';
		
    }
}

.font-icon-mobile-sms {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAAB';
		
    }
}

.font-icon-money-calculator {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAAC';
		
    }
}

.font-icon-phone2 {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EACC';
		
    }
}

.font-icon-print {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAD1';
		
    }
}

.font-icon-security {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAF1';
		
    }
}

.font-icon-touchid {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB04';
		
    }
}

.font-icon-add {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA10';
		
    }
}

.font-icon-alert-filled {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA11';
		
    }
}

.font-icon-alert-line {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA12';
		
    }
}

.font-icon-angle-left-double {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA08';
		
    }
}

.font-icon-angle-left {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA14';
		
    }
}

.font-icon-angle-right-circle {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA15';
		
    }
}

.font-icon-angle-right-double {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA09';
		
    }
}

.font-icon-angle-right {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA17';
		
    }
}

.font-icon-angle {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA17';
		
    }
}

.font-icon-arrange {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA01';
		
    }
}

.font-icon-arrow-down {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA0A';
		
    }
}

.font-icon-arrow-left {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA19';
		
    }
}

.font-icon-arrow-up {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA0B';
		
    }
}

.font-icon-arrow-right {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA1A';
		
    }
}

.font-icon-arrow {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA1A';
		
    }
}

.font-icon-attachment {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB17';
		
    }
}

.font-icon-cancel-x {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA2D';
		
    }
}

.font-icon-times-circle {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA2E';
		
    }
}

.font-icon-canceled {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA2E';
		
    }
}

.font-icon-checkmark {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA3C';
		
    }
}

.font-icon-check {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA3C';
		
    }
}

.font-icon-checkmark-circle {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA3F';
		
    }
}

.font-icon-circle-check-line {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA3F';
		
    }
}

.font-icon-success {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA3F';
		
    }
}

.font-icon-circle-check-filled {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA3F';
		
    }
}

.font-icon-circle-filled {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA42';
		
    }
}

.font-icon-cicle-info-line {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA43';
		
    }
}

.font-icon-credit-card-info {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA43';
		
    }
}

.font-icon-info {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA43';
		
    }
}

.font-icon-info-serif {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA43';
		
    }
}

.font-icon-circle-info-filled {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA43';
		
    }
}

.font-icon-circle-info-line {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA44';
		
    }
}

.font-icon-compass {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA47';
		
    }
}

.font-icon-compose {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB18';
		
    }
}

.font-icon-crosshair {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA50';
		
    }
}

.font-icon-cut-out {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA51';
		
    }
}

.font-icon-delete {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA53';
		
    }
}

.font-icon-design-your-card {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA54';
		
    }
}

.font-icon-download {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA5D';
		
    }
}

.font-icon-eb-apply {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA5E';
		
    }
}

.font-icon-edit-multiple {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA61';
		
    }
}

.font-icon-edit-info {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA62';
		
    }
}

.font-icon-edit {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA62';
		
    }
}

.font-icon-error {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA6C';
		
    }
}

.font-icon-filter {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA78';
		
    }
}

.font-icon-generic-icon-folder {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA81';
		
    }
}

.font-icon-hamburger {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA85';
		
    }
}

.font-icon-question {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA88';
		
    }
}

.font-icon-question-outline {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA88';
		
    }
}

.font-icon-support {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA88';
		
    }
}

.font-icon-help {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA88';
		
    }
}

.font-icon-hide {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA89';
		
    }
}

.font-icon-i_pending {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA8D';
		
    }
}

.font-icon-image {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB19';
		
    }
}

.font-icon-loading {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA9A';
		
    }
}

.font-icon-location-circle {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA9C';
		
    }
}

.font-icon-location-pin-bp {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA9C';
		
    }
}

.font-icon-locate-marker {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA9C';
		
    }
}

.font-icon-login-activity {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAA2';
		
    }
}

.font-icon-logout {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAA3';
		
    }
}

.font-icon-mail-out {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAA4';
		
    }
}

.font-icon-menu {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAA9';
		
    }
}

.font-icon-no-category {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAB6';
		
    }
}

.font-icon-open-tab {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EABD';
		
    }
}

.font-icon-pencil {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAC2';
		
    }
}

.font-icon-i-pending {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAC4';
		
    }
}

.font-icon-pending {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAC4';
		
    }
}

.font-icon-pendingauth {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAC5';
		
    }
}

.font-icon-power {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAD0';
		
    }
}

.font-icon-retry {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAEB';
		
    }
}

.font-icon-search {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAEE';
		
    }
}

.font-icon-settings-slider {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAF3';
		
    }
}

.font-icon-show {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAF7';
		
    }
}

.font-icon-split {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAF9';
		
    }
}

.font-icon-star-filled {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAFA';
		
    }
}

.font-icon-star-line {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAFB';
		
    }
}

.font-icon-sync {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAFF';
		
    }
}

.font-icon-times {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB03';
		
    }
}

.font-icon-trashcan {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB08';
		
    }
}

.font-icon-upload {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA03';
		
    }
}

.font-icon-view-fullsite {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB10';
		
    }
}

.font-icon-warning {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB11';
		
    }
}

.font-icon-bills-utilities {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA21';
		
    }
}

.font-icon-business_services {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA29';
		
    }
}

.font-icon-entertainment {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA6A';
		
    }
}

.font-icon-financial {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA79';
		
    }
}

.font-icon-food-dining {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA7A';
		
    }
}

.font-icon-gifts-donations {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA83';
		
    }
}

.font-icon-health-fitness {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA87';
		
    }
}

.font-icon-home-category {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA8C';
		
    }
}

.font-icon-house {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA8C';
		
    }
}

.font-icon-income {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA8E';
		
    }
}

.font-icon-kids {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA95';
		
    }
}

.font-icon-personal-care {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAC8';
		
    }
}

.font-icon-pets {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAC9';
		
    }
}

.font-icon-shopping {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAF6';
		
    }
}

.font-icon-taxes {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB01';
		
    }
}

.font-icon-transport {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB07';
		
    }
}

.font-icon-travel {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB09';
		
    }
}

.font-icon-trend-up {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB0A';
		
    }
}

.font-icon-contact-book {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA48';
		
    }
}

.font-icon-eb-enroll {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA5F';
		
    }
}

.font-icon-family-banking {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA71';
		
    }
}

.font-icon-user-locked {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB0E';
		
    }
}

.font-icon-person {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB0F';
		
    }
}

.font-icon-profile {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB0F';
		
    }
}

.font-icon-user {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB0F';
		
    }
}

.font-icon-accounts {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA0C';
		
    }
}

.font-icon-ach-collections {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA0E';
		
    }
}

.font-icon-ach-payments {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA0E';
		
    }
}

.font-icon-add-account {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA0F';
		
    }
}

.font-icon-bill-pay {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA20';
		
    }
}

.font-icon-budgets {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA24';
		
    }
}

.font-icon-business-admin {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA26';
		
    }
}

.font-icon-calc-and-calendar {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA2A';
		
    }
}

.font-icon-check-services {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA3B';
		
    }
}

.font-icon-content {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA49';
		
    }
}

.font-icon-loan-advance {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA4D';
		
    }
}

.font-icon-u-consumer-lending {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA4D';
		
    }
}

.font-icon-courtesy-pay {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA4D';
		
    }
}

.font-icon-dashboard {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA52';
		
    }
}

.font-icon-edocs {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA63';
		
    }
}

.font-icon-envelope {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA6B';
		
    }
}

.font-icon-ficoscore {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA07';
		
    }
}

.font-icon-investments {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA93';
		
    }
}

.font-icon-locations {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA9F';
		
    }
}

.font-icon-manage-cards {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAA5';
		
    }
}

.font-icon-member-services {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAA7';
		
    }
}

.font-icon-more {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAAD';
		
    }
}

.font-icon-mortgage {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAAE';
		
    }
}

.font-icon-pay-people {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EABE';
		
    }
}

.font-icon-payroll-distribution {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAC1';
		
    }
}

.font-icon-piggy {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EACD';
		
    }
}

.font-icon-quick-apply {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAE3';
		
    }
}

.font-icon-remote-deposit-mobile {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAEA';
		
    }
}

.font-icon-remote-deposit {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAEA';
		
    }
}

.font-icon-n-loyalty-checking {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAEC';
		
    }
}

.font-icon-rewards {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAEC';
		
    }
}

.font-icon-settings {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAF4';
		
    }
}

.font-icon-skip-a-pay {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAF8';
		
    }
}

.font-icon-sweeps {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EAFE';
		
    }
}

.font-icon-transfers {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB05';
		
    }
}

.font-icon-transfer {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB05';
		
    }
}

.font-icon-wire-transfers {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EB12';
		
    }
}

.font-icon-zelle {
    &:not([data-font-options*="after"]):before,
    &[data-font-options*="before"]:before,
    &[data-font-options*="after"]:after {
        content: '\EA13';
		
    }
}
