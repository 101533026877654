﻿// Aggregate External Accounts Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base aggregate external accounts styles

#ai-BankModal a {
    text-decoration: none;
}

.ai-bank-connect {
    background: #F0F0F0;
    border-radius: 5px;
    margin-bottom: 15px;
}

.ai-bank-information,
.ai-bank-credentials,
.ai-bank-challenges,
.ai-fine-print {
    padding: 0px 15px;
}

.ai-bank-connect .errors {
    margin: 15px 0 8px;
}

.ai-bank-content .button {
    margin: 10px 0;
}

.ai-choose-bank,
.ai-another-user {
    position: absolute;
    top: 20px;
    right: 15px;
}

// Must be a seperate class because of the transform
.ai-choose-bank .arrow-left,
.ai-another-user .arrow-left {
    background: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png") repeat scroll -60px 0px transparent;
    display: inline-block;
    height: 8px;
    transform: rotate(180deg);
    margin-right: 3px;
    width: 6px;
}

.ai-bank-information {
    background: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    line-height: 160%;
    padding: 15px;
    position: relative;
}

.ai-bank-information a {
    padding-bottom: 2px;
}

.ai-bank-name {
    display: block;
    font-size: 18px;
    font-weight: 700;
    overflow: hidden;
    padding: 5px 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;
}

.ai-bank-credentials {
    padding: 10px 15px 15px;
}

.ai-bank-credentials .field {
    padding-top: 15px;
}

.ai-bank-credentials .field:first-child {
    padding-top: 0;
}

.ai-bank-credentials label, .ai-bank-challenge-inputs label {
    float: none;
    padding: 7px 0 6px;
    width: 94%;
}

.ai-logging-in {
    font-size: 14px;
    font-weight: 700;
}

.ai-bank-challenges {
    padding: 20px 15px 15px;
    position: relative;
}

.ai-bank-challenges label.ai-username {
    font-size: 18px;
    font-weight: 400;
    padding-top: 10px;
    text-transform: none;
}

.ai-bank-challenges select {
    border: 1px solid #D0D0D0;
    border-radius: 4px;
    box-shadow: 0 1px 3px #D9D9D9 inset;
    box-sizing: border-box;
    font-size: 13px;
    height: 34px;
    padding: 5px;
    width: 200px;
}

.ai-bank-challenges .field.ai-question {
    padding-top: 15px;
}

.ai-fine-print {
    font-size: 11px;
    line-height: 140%;
    padding: 5px 15px 15px;
}

.warning-prompt-content {
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 10px;
}

.warning-prompt-content ul {
    padding: 5px 10px 15px;
}

.warning-prompt-content li {
    font-size: 12px;
    font-weight: 400;
}

.warning-prompt-buttons:after {
    content: '';
    clear: both;
    display: block;
}

.warning-prompt-buttons .button {
    margin-left: 5px;
}

// Custom dropdown for bank verification challenge questions
.field[data-type="dropdown"] {
    overflow: visible;
}

.ai-form-challenges select {
    display: none;
}

.ai-challenges-wrapper {
    margin-top: 5px;
    position: relative;
    width: 50%;
}

.ai-challenges-selected {
    background-color: #FFFFFF;
    box-shadow: 0 1px 3px #D9D9D9 inset;
    box-sizing: border-box;
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    color: #333333;
    cursor: pointer;
    display: block;
    font-size: 13px;
    height: 34px;
    margin: 0;
    outline: none;
    padding: 8px 30px 8px 8px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.ai-challenges-selected:after {
    background: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png") no-repeat;
    background-position: -97px -11px;
    content: " ";
    height: 20px;
    margin-top: -10px;
    position: absolute;
    right: 0;
    top: 50%;
    transition: 0.2s all;
    width: 20px;
}

.ai-challenges-selected.open {
    background-color: #FFF !important;
    border-color: #91B2D3 !important;
    box-shadow: inset 1px 1px 2px #e9e9e9, 0 0 4px rgba(145, 178, 211, 0.8) !important;
}

// Begin Dropdown ul with items
.ai-challenges-list {
    background: #fff;
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    box-sizing: border-box;
    display: none;
    font-size: 13px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 2;

    &.open {
      display: block !important;
    }
}

.ai-challenges-list .selected {
    background: #e1e1e1;
    text-shadow: none;
    color: #000 !important;
    position: relative;

    &:hover {
      background: #e1e1e1;
      position: relative;
    }
}

.ai-challenges-list li {
    padding: 10px 8px;

    &:hover {
      background: #f1f1f1;
    }
}