﻿// Profile Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base profile styles

// Profile Lite

div#profile_lite {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
    background: none;
    border-radius: 5px;
    border: 0 none;
    float: right;
    height: 50px;
    margin-top: 14px;
    padding: 12px;
    position: relative;
    text-align: left;
    width: 230px;

    h3 {
        font-size: 13px;
        font-weight: bold;
        height: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #333333;
    }

    a {
        color: #333333;
        text-decoration: underline;

        &.edit-profile {
            display: block;
            float: right;
            margin-left: 10px;
            width: 50px;
            height: 50px;
            text-decoration: none;
        }
    }

    em {
        font-size: 11px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

  div#profile_lite h3, div#profile_lite em, div#profile_lite div.extra {
      margin-bottom: 5px;
  }
  
  div#profile_lite a,
  div#profile_lite div.extra a {
      font-weight: bold;
      text-decoration: none;
  }
  
  div#profile_lite a.edit-profile {
      float: left;
      margin-left: 0;
      margin-right: 15px;
  }
  
  div#profile_lite span.img img {
      border: 0 none;
      border-radius: 3px;
      margin-right: 10px;
      height: 50px;
      width: 50px;
  }
  
  div#profile_lite div.extra {
      left: auto !important;
      bottom: auto !important;
      right: auto !important;
      position: relative !important;
      margin-top: 5px;

      a {
          margin: 0 15px 0 0;
          text-decoration: underline;
      }
  }