﻿// Module Box Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base module box styles

div.col1 {
	margin-left: 0;
}

div.mod {
	padding: 5px;
	background: #F5F5F5;
	position: relative;
	border: 0 none;
	border-radius: 4px;

	h4 {
		font-size: 14px;
		color: #333333;
		font-weight: bold;
		margin: 4px 0 6px 2px;
	}

	div.mod-content {
		background: #FFF;
		overflow: hidden;
		border-radius: 3px;
		border: 0 none;
	}

	div.links {
		right: 7px;
		top: 7px;

		a {
			color: #FFFFFF;
			display: block;
			font-size: 0;

			&:before {
				display: block;
				font-family: 'Alkami';
				font-size: 18px;
				margin: 13px 5px 0;
				text-align: right;
			}

			&.new-category:before {
				content: char-from-code($font-icon-add);
			}

			&.policy:before,
			&.info-popup:before {
				content: char-from-code($font-icon-question);
			}

			&.view-more:before {
				content: char-from-code($font-icon-angle-right);
				font-size: 24px;
				margin-right: 8px;
			}

			&.edit-settings:before,
			&.edit:before {
				content: char-from-code($font-icon-settings);
			}
		}
	}
}

div.mod-content span.action {
	margin-top: 10px;
}

// Tabbed Module
// =============================================================================

div.tabbed div.x-panel-bwrap {
	border: 0 none;
  border-radius: 3px;
}

div.tabbed li.x-tab-strip-active a.x-tab-right {
  border-radius: 0 0 3px 3px;
}

ul.x-tab-strip-bottom li.x-tab-strip-active .x-tab-right,
ul.x-tab-strip-bottom li.x-tab-strip-active.x-tab-strip-over .x-tab-right {
	border: 0 none;
}

ul.x-tab-strip-bottom span.x-tab-strip-text {
	color: rgba(255, 255, 255, 1);
}

ul.x-tab-strip-bottom li.x-tab-strip-over span.x-tab-strip-inner span.x-tab-strip-text {
	color: rgba(255, 255, 255, 0.7);
}

ul.x-tab-strip-bottom li.x-tab-strip-active span.x-tab-strip-text,
ul.x-tab-strip-bottom li.x-tab-strip-active.x-tab-strip-over span.x-tab-strip-inner span.x-tab-strip-text {
	color: #333333;
}


.mod.inner-title.shared-title .mod-content {
	border-top: none;
}

.mod-inner-titlebar {
	height: 30px;
	padding-bottom: 10px;
	position: relative;
	margin-bottom: 20px;
}

.mod.inner-title.shared-title .mod-inner-titlebar {
	background: #FFF;
	border: 1px solid #D0D0D0;
	border-bottom: none;
	padding: 10px;
	height: 40px;
	margin-bottom: 0px;
}

.mod-inner-titlebar-content {
	border-bottom: 1px dashed #d5d5d5;
	position: relative;
	padding-bottom: 10px;
}

.mod-inner-titlebar h4 {
	margin-bottom: 3px;
	margin-left: 0;

	a {
		font-size: 11px;
		font-weight: normal;
	}
}

.mod-inner-titlebar-actions {
	position: absolute;
	right: 0;
	top: 0;
}

.mod {
	.links {
		position: absolute;
		right: 5px;
		top: 5px;
		height: 14px;
		line-height: 14px;
		text-transform: lowercase;

		a {
			font-size: 11px;
		}
	}

	.buttons {
		border-top: 1px dashed #DDD;
		padding-top: 10px;
		margin-top: 20px;
		text-align: right;
	}
}