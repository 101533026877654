﻿// PDF Confirmation Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base pdf confirmation styles

.pdf-access {
	clear: both;
	font-weight: 400;
	padding: 15px 0;

	ol {
		padding-left: 20px;

		li {
			list-style: decimal;
			padding-bottom: 10px;
		}
	}

	h4 {
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 5px;
	}

	.input-wrapper.required:after {
		display: inline-block;
    }

    input[type=text] {
        margin-top: 15px;
    }
}

#pdf_option {
	margin: 40px 0;
}

input#pdf_code_entered_by_user {
	margin-left: 25px;
	margin-top: 1px;
	position: relative;
}

#pdf_disclosure {
	margin: 0;
}

#core_verification #pdf_access, #accept_registration_disclosure #pdf_access, #accept_disclosure_container #pdf_access {
	display: none;
}

#core_verification .show-pdf-confirmation #pdf_access {
	display: block;
}