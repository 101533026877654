// Base Checkbox Styles
// Included in every theme via the _base.scss file
//
// This partial setsup the base theme styles for checkboxes

.checkbox-wrapper {
    input[type="checkbox"] {
      height: 0;
      // Set the opacity instead of display:none to receive focus
      opacity: 0;
      position: absolute;
      width:0;

      &:checked ~ label:before {
          background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png");
          background-repeat: no-repeat;
          background-position: -57px -416px;
      }

  		&:focus ~ label:before {
  			box-shadow: inset 1px 1px 2px #e9e9e9, 0 0 4px rgba(145, 178, 211, 0.8);
  			border-color: #91B2D3;
  		}

  		&:not(:disabled) ~ label {
  			cursor: pointer;

  			&:hover:before {
  				border-color: #D0D0D0;
  			}
  		}
    }

    > label {
        display: inline-block;
        float: none;
        margin: 0;
        padding: 5px 0 5px 32px !important;
        position: relative;
        width: auto;

        &:before {
            background-color: #ffffff;
            background-image: none;
            border: $input-border;
            border-radius: 3px;
            box-shadow: none;
            content: '';
            display: block;
            height: 18px;
            left: 4px;
            margin-top: -10px;
            position: absolute;
            top: 50%;
            width: 18px;
        }

        &:not(.disabled):hover:before {
            border-color: #D0D0D0;
        }

        // For IE8 only
        &.checked {
	        background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png");
	        background-repeat: no-repeat;
	        background-position: -57px -416px;
        }
    }

	&.checkbox-right {
		> label {
			width: 100%;

			&:before {
				left: auto;
				right: 10px;
			}
		}
	}

	&.disabled > label {
		cursor: default;
	}

	.checkbox-content {
		padding-left: 32px;
	}

	/* Various radio button options - put on parent container. */
    // If you add a new option DOCUMENT IT!
	&[data-checkbox-options*="block"] label {
		display: block;
	}

	&[data-checkbox-options*="left"] label {
		float: left;
	}

	&[data-checkbox-options*="inverted"] label {
		padding: 5px 32px 5px 0;

		&:before {
			left: inherit;
			right: 4px;
			}
	}

	&[data-checkbox-options*="flush-left"] label {
		float: left;
		padding: 5px 0 5px 28px !important;

		&:before {
			left: 0;
		}
	}

    // Use this to remove any extra padding around the checkbox.
    // This is mainly used when you only want to display a checkbox and no label is necessary.
    &[data-checkbox-options*="empty-label"] label {
        padding-left: 24px !important; // MAGIC NUMBER: 20px (checkbox width) + 4px (the left property)
    }
}

.modal .x-plain-body form span.checkbox-wrapper >  label {
  cursor: pointer;
  display: inline-block;
  float: none;
  margin: 0;
  padding: 5px 0 5px 32px;
  position: relative;
  width: auto;
}

// Checkbox button
// =============================================================================
[data-element="checkbox"] {
    display: block;
    padding: 7px 0;

    input {
        display: none;
    }

    > label {
        cursor: pointer;
        display: inline-block;
        float: none;
        margin: 0;
        padding: 0 0 0 28px;
        position: relative;
        width: auto;
    }

    > label:before {
        background-color: transparent; // Had to make this transparent for IE8
        background-image: none;
        border: 1px solid #D0D0D0;
        border-radius: 3px;
        box-shadow: 0 1px 3px #D9D9D9 inset;
        content: '' ;
        display: block;
        height: 20px;
        left: 0;
        position: absolute;
        top: -2px;
        width: 20px;
    }

    > label:hover:before {
        border-color: #D0D0D0;
    }

    input:checked + label:before {
	    background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png");
	    background-repeat: no-repeat;
	    background-position: -57px -416px;
    }
}

// For IE8 only
.toggle-range label.checked {
	background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png");
	background-repeat: no-repeat;
	background-position: -57px -416px;
}