﻿// Alert Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base alert styles

// Alerts
// =============================================================================
#primary_widget_content .alert {
    padding: 8px 35px 8px 14px;
	margin-bottom: 20px;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
	background-color: #fcf8e3;
	border: 1px solid #fbeed5;
	border-radius: 4px;
	color: #c09853 !important;
	font-size: 14px;

	p {
		margin-bottom: 0;
		line-height: 20px;
	}

	h4 {
		margin: 0;
		font-size: 17px;
		font-weight: bold;
	}
}

#statement_settings_link + .alert {
	clear: both;
}