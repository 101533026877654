﻿// Radio Button Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base radio button styles

.radio-wrapper {
    // Hide the input and trigger a state change to the label based on checked status.
    input[type="radio"] {
  		height: 0;
  		// Set the opacity instead of display:none to receive focus
      opacity: 0;
      position: absolute;
      width:0;

      & + label:after {
        background: #4371AD;
        border-radius: 100%;
        content: ' ';
        display: block;
        height: 13px;
        left: 9px;
        position: absolute;
        top: 9px;
        width: 13px;
        transform: scale(0, 0);
        transition: transform 0.2s cubic-bezier(0.8, 0.1, 0.2, 1.5);
      }
      &:checked + label:after {
        transform: scale(1, 1);
      }

      &:focus + label:before {
        box-shadow: inset 1px 1px 2px #e9e9e9, 0 0 4px rgba(145, 178, 211, 0.8);
  			border-color: #91B2D3;
      }
    }
    // Create a styled radio button as a psuedo element.
    label {
        cursor: pointer;
        display: block;
        font-size: 13px;
        font-weight: 700;
        padding: 10px 0 10px 35px;
        position: relative;
        &:before {
            background: #ffffff;
            border: 1px solid #707070;
            border-radius: 100%;
            content: ' ';
            display: block;
            height: 23px;
            left: 3px;
            position: absolute;
            top: 3px;
            width: 23px;
        }
    }
	// Various radio button options - put on parent container.
	&[data-radio-options*="inline-block"] label {
		display: inline-block;
	}
	&[data-radio-options*="left"] label {
		float: left;
	}

    // Custom option to vertically align radio button in the parent container instead of top.
    &[data-radio-options*="v-middle"] label {
        &:before {
            margin-top: -13px !important;
            top: 50% !important;
        }
        &:after {
            margin-top: -7px !important;
            top: 50% !important;
        }
    }

    &[data-radio-options*="flat"] label {
        padding-top: 0;
        padding-bottom: 0;
    }
}