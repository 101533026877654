﻿// Base Wizard Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base wizard styles

.ui-widget-content.wizard {
	background: none;
	border: none;
}

.modal .ui-widget-content.wizard .x-plain-body {
	padding-left: 0;
}

.ui-widget-content.wizard fieldset {
	clear: both;
}