﻿// Shame. Shame. Shame. Shame. Shame.
//
// Make this go away...


// =============================================================================
// Add recipient at another bank
// =============================================================================

// Specific for Transfers and Accounts v2 and Billpay
//==============================================================================

// Custom Widths
#create_payee_wizard,
#transfer_recipient_settings_external,
#edit_payee {
    ul.fields.clearfix,
    ul.fields li.clearfix {
        overflow: visible;
    }

    ul.fields li {
        div > img {
          right: 0 !important;
        }

        div.x-form-field-wrap input {
            width: 232px !important;
        }

        div#floating_account_number {
            width: 323px !important;
        }

        label:not(.file-upload) {
            width: 180px !important;
        }
        .image-tooltip-ct {
            width: auto !important;
        }
    }

    ul.fields div#routing_wrapper,
    ul.fields div#account_wrapper {
        margin-top: 0px !important;
        position: absolute;
        right: 20px !important;
        top: 60px;
        width: auto !important;
        z-index: 1;
    }
}

// Evenly space payee image row in Billpay modal
#create_payee_wizard #payee_image {
    margin-bottom: 1px;
}

// CSS arrow pointed up
#floating_routing_number:before,
#floating_account_number:before {
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgba(0, 0, 0, 0.5);
    position: absolute;
    top: -10px;
    right: 111px;
}

// Add External Account Modal
// =============================================================================

// Transfers Only
#transfer_recipient_settings_external ul.fields li div,
.transfers .modal-body #ACHContent input,
li#account_type div input {
  width: 220px !important;
}

// Correcting alignment issue reported in DEV-32302
// Suspected Cause: specificity war with Accounts.scss in determining float value
#transfer_recipient_settings_external ul.fields li div.btn-group.bootstrap-select {
    float: right;
    width: 220px;
}



// Fields and Formatting
// =============================================================================
div#transfer_recipient_settings_external .errors.icon:before {
  // Necessary to remove the error icon above validation field
  display: none;
}

#display_bank {
    display: block;
}

li#routing_number_label label{
  padding-bottom: 15px;
}

span#bank_name_display {
  display: block;
  float: right;
  font-size: 10px;
  width: 232px;
}

#slides img, #slides a {
	width: 400px !important;
	height: 65px !important;
}

//Settings widget specific styles
div.widget-list-container div.details:before {
    color: $input-gray;
    display: block;
    float: left;
    font-family: 'Alkami';
    font-size: 32px;
    line-height: 32px;
    height: auto;
    width: 55px;
}

// New Content Badge

div#primary_widget_title ul#related_content li > a span.new {
	background-clip: padding-box;
	border-radius: 7px;
	position: absolute;
	top: -5px;
	right: 3px;
	text-indent: 0;
	font-size: 9px;
	height: 14px;
	width: 14px;
	display: block;

	em {
		display: block;
		line-height: 0;
		font-style: normal;
		font-weight: bold;
		color: #FFF;
		position: absolute;
		left: 5px;
		top: 7px;
	}
}

#confirm-contact-info-form ol li {
	list-style: decimal inside;
	margin-bottom: 10px;
	position: relative;
}

.confirm-contact-info-form p {
	margin: 0 0 10px;
}

.ie-iframe-overlay-fix {
	border: none;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: -1;
}

div {
	&#primary_widget div#primary_widget_content div#attachments_container.mod div.mod-content strong {
		font-weight: normal;
	}

	&#insufficient {
		background: url("https://assets.orb.alkamitech.com/production/assets/global/images/errors/insufficient_permissions.png") no-repeat scroll center 40px transparent;
		height: 200px;
		margin-top: 40px;
		padding-top: 185px;
		text-align: center;
	}

	&#primary_widget_title ul#related_content li > a span.new {
		display: none !important;
	}
}

#txn_details {
	color: #333333;

	.x-plain-body {
		min-height: 300px;
		height: auto !important;
	}

	h3 {
		font-size: 14px;
		margin: 20px 0 10px;
		font-weight: bold;
	}

	div.field {
		margin: 6px 0;
		overflow: hidden;

		label {
			font-weight: bold;
			float: left;
			width: 130px;
			padding: 0;
			margin: 0;
			font-size: 12px;
			color: #333333;
			text-shadow: none;
		}

		p {
			float: left;
			width: 280px;
			clear: none;
		}
	}
}

li#device-security-field span.labelText {
	text-transform: none;
}