﻿// Base Progress Bar Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base progress bar styles

.ui-progressbar-value {
	position: relative;
}

.progress-bar-holder {
	&.green .ui-progressbar .ui-progressbar-value {
		border-color: #167A49;
		background-color: #248E5A;
		background-image: none;
	}

	&.yellow .ui-progressbar .ui-progressbar-value {
		border-color: #da9106;
		background-color: #efcd47;
		background-image: none;
	}

	&.red .ui-progressbar .ui-progressbar-value {
		border-color: #800806;
		background-color: #950E0C;
		background-image: none;
	}
}

.progress-amount {
	display: block;
	position: absolute;
	right: 5px;
	top: 2px;
	color: #FFF;
	text-shadow: none;

	&.just-started {
		color: #aaa;
		text-shadow: white 0px -1px 1px;
		filter: none;
	}
}

div {
	&#savings_goals div.ui-progressbar-value span.progress-amount.just-started {
		color: #B5B5B5;
		text-shadow: #ffffff 0px -1px 1px;
	}

	&.x-tab-panel-footer-plain {
		margin-top: -1px;

		.x-tab-strip-spacer {
			display: none;
		}
	}
}