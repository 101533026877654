﻿// Base Error Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base error styles

input.error {
	border-color: #982f24;
	background-color: #f5ebea;
	box-shadow: inset 1px 1px 3px #f3e7e5;
}

div.errors {
	background: url("https://assets.orb.alkamitech.com/production/assets/global/images/icon_error.png") no-repeat 15px 15px #FFFFFF;
	background-clip: padding-box;
	border-radius: 5px;
	color: #333333;
	float: none;
	padding: 16px;
	padding-left: 50px;
	margin-bottom: 10px;
	border: 2px solid #C11826;

	&.one {
		padding-bottom: 0px;
	}

	strong {
		display: block;
		margin-bottom: 5px;
	}

	.strong {
		font-weight: bold;
		margin: 3px 0;
	}

	ul {
		margin-top: 15px;
		margin-left: 15px;

		li {
			margin-top: 5px;
			list-style: square;

			&:first-child {
				margin-top: 0;
			}
		}
	}

	label.error {
		width: auto;
		float: none;
		padding: 0;
		margin: 0;
		font-weight: normal;
	}
}

.modal div.errors {
	display: block;
	float: none;
}

span.error-icon {
	background: url("https://assets.orb.alkamitech.com/production/assets/global/images/alert.png") no-repeat 0 center;
	width: 16px;
	height: 16px;
	float: left;
	display: block;
	margin-left: 5px;
	padding: 6px 0;
}

/***** Pop out errors ******/

.modal .errors + br.clear {
	display: none;
}

.error-field {
	border: 2px solid #d83939 !important;
	margin-top: -1px;
}

.error-display {
	background-color: #f2dede;
	border-color: #ebccd1;
	border-radius: 3px;
	clear: both;
	color: #a94442;
	display: block;
	font-weight: bold;
	line-height: 1;
	margin-bottom: 3px;
	padding: 6px;
}
