﻿// Dropdown Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base dropdown styles

div#widget_actions span.dropdown-field-wrapper {
    margin-left: 10px;
}

.dropdown-field-wrapper + .submit {
    display: block;
    margin-top: 30px;
}

div#widget_actions span.dropdown-field-wrapper a.button,
span.dropdown-field-wrapper a.button {
    margin-left: 0;
    padding-right: 35px;
    position: relative;
}

span.dropdown-field-wrapper a.button:after {
    background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png");
    background-position: -120px 0;
    content: " ";
    display: block;
    height: 6px;
    margin-top: -1px;
    position: absolute;
    right: 10px;
    top: 50%;
    width: 10px;
}

span.dropdown-field-wrapper a.button:hover:after {
    background-position: -120px -20px;
}

div.actions span.dropdown-field-wrapper ul.dropdown-list {
    width: auto;
}

div.actions ul.dropdown-list li {
    margin: 0;
    padding: 0;
}

div.actions ul.dropdown-list li a {
    box-sizing: border-box;
    color: #333333;
    display: block;
    height: 44px;
    line-height: 22px;
    padding: 11px 10px 9px 15px;
}

div.actions ul.dropdown-list li a.icon {
    padding-left: 40px;
}

span.dropdown-field-wrapper {
  display: inline-block;
  position: relative;
}

span.dropdown-field-wrapper a.dropdown-field {
  background-color: #FFFFFF;
  box-sizing: border-box;
  border: $input-border;
  border-radius: 3px;
  color: #333333;
  cursor: pointer;
  display: block;
  font-size: 13px;
  height: 34px;
  margin: 0;
  overflow: hidden;
  padding: 8px 30px 8px 8px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

a.dropdown-field,
ul.dropdown-list li {
  user-select: none;
}

span.dropdown-field-wrapper a.dropdown-field:hover {
  border-color: #D0D0D0;
}

// Match green ext.js "selected" styling (for now)
span.dropdown-field-wrapper.selected a.dropdown-field {
	background-color: #FFF !important;
	border-color: $input-gray;
    box-shadow: none;
}

span.dropdown-field-wrapper a.dropdown-field:after {
  background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png");
  background-position: -97px -11px;
  content: " ";
  display: block;
  height: 20px;
  margin-top: -10px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 20px;
}

span.dropdown-field-wrapper.selected a.dropdown-field:after {
  background-position: -97px -31px;
}

  ul.dropdown-list {
    margin-bottom: 20px;
    min-width: 100%;
    width: auto;
    z-index: 9002;
  }

  ul.dropdown-list ul {
	  z-index: 9001;
  }

  ul.dropdown-list,
  ul.dropdown-list ul {
	  background-color: #FFFFFF;
	  border: 1px solid #D0D0D0;
	  border-radius: 3px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
	  box-sizing: border-box;
	  color: #333333;
	  display: none;
	  margin: 0;
	  position: absolute;
	  top: 36px;
	  white-space: nowrap;
  }

  ul.dropdown-list.category-dropdown,
  ul.dropdown-list.category-dropdown ul {
    top: 28px;
  }

  ul.dropdown-list.category-dropdown li:hover {
    background-color: #E3EAF2;
    color: #475C81;
  }

  ul.dropdown-list.category-dropdown li:active,
  ul.dropdown-list.category-dropdown li:focus {
    background-color: #D9E6F2;
  }


  // Change dropdown direction if too close to the bottom of the page
  ul.dropdown-list.pull-up {
	  bottom: 28px;
	  top: auto;
  }

  .selected ul.dropdown-list,
  ul.dropdown-list li:hover ul,
  ul.dropdown-list li.expanded ul {
	  display: block !important;
  }
	  a ~ ul.dropdown-list li:hover ul,
    a ~ ul.dropdown-list li.expanded ul {
		  left: 100%;
		  top: -1px;
	  }

    ul.dropdown-list li {
	    font-size: 13px;
      padding: 11px 10px 9px 10px;
      position: relative;
    }

     ul.dropdown-list > li > ul {
       min-width: 180px;
     }

	    ul.dropdown-list > li:hover ul:before,
      ul.dropdown-list > li.expanded ul:before {
		    background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png");
		    background-position: -50px 9px;
		    content: " ";
		    display: block;
		    height: 20px;
		    left: -27px;
		    position: absolute;
		    top: 6px;
		    width: 20px;
	    }

	  ul.category-dropdown > li.uncategorized {
		  font-style: italic;
	  }

  ul.dropdown-list li:first-child {
	  border-radius: 3px 3px 0 0;
  }

  ul.dropdown-list li:last-child {
	  border-radius: 0 0 3px 3px;
  }
	  ul.dropdown-list li:hover,
      ul.dropdown-list li.expanded {
		  background-color: #F1F1F1;
		  color: #333333;
		  cursor: pointer;
		  text-shadow: 0 0 1px rgba(255, 255, 255, 0.50);
	  }

	  ul.dropdown-list li:active,
	  ul.dropdown-list li:focus {
	      background-color: #E1E1E1;
	  }

  ul.dropdown-list li.link {
	  color: #4974A4;
  }

  ul.dropdown-list li.icon:before {
	  background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/icons_sprite.png");
	  content: '';
	  display: block;
	  height: 20px;
	  left: auto;
	  position: absolute;
	  right: 6px;
	  top: 7px;
	  width: 20px;
  }

  ul.dropdown-list li.link:hover {
	  color: #4873A9;
  }

// Override .selected styles used for account dropdowns
span.dropdown-field-wrapper.selected {
  background: none;
  border: 0 none;
  text-shadow: none;
}

// Fix width of custom dropdown for account type selector
.accounts .x-layer.x-combo-list,
.accounts .x-combo-list-inner,
.accounts .x-combo-list-item,
.accounts .x-combo-list-item .x-combo-selected {
  width: 230px !important;
}

.x-combo-list {
	.x-combo-list-inner .list-item {
		font-size: 12px;
		width: auto;
		text-align: left;
		position: relative;
		display: block;
		padding: 10px 10px 10px 20px;
		cursor: pointer;
		border: 1px solid #d1d2d4;
		margin: 2px 0;
		color: #707070;

		&.x-combo-selected {
			color: #fff;
		}
	}

	&.icon .x-combo-list-inner .list-item {
		padding: 10px 10px 10px 60px !important;
	}
}

.x-combo-list-inner .list-item {
	span.bar {
		display: block;
		position: absolute;
		width: 10px;
		top: 0;
		left: 0;
		height: 40px;
		margin: 5px 10px 5px 5px;
	}

	img {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 50px;
		height: 50px;
	}

	h4 {
		display: block;
		font-size: 100%;
		font-weight: bold;
        line-height: normal;
		margin-bottom: 3px;
		margin-left: 5px;
		overflow-x: hidden;
		position: relative;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
	}

	h3 {
		display: block;
		font-size: 100%;
        line-height: normal;
		overflow-x: hidden;
		position: relative;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
	}

	em {
		display: block;
		float: left;
		margin-left: 5px;
	}

	small {
		display: block;
		float: right;
		font-weight: bold;
		font-size: inherit;
	}
}

// Dropdown Search
div.search-form {
	.x-form-field-wrap.x-form-field-trigger-wrap {
		box-sizing: border-box;
		height: 26px;
		margin-bottom: 5px;
		width: 200px !important;

		input {
			box-sizing: border-box;
			height: 26px;
			margin-bottom: 5px;
			width: 200px !important;
		}
	}

	input {
		&#date, &#description {
			width: 186px;
		}
	}

	.form-fields > li {
		margin-bottom: 10px;
	}
}

/*********** dropdown width override for extjs **********/

.x-form-field-wrap.x-form-field-trigger-wrap {
	width: 200px !important;
}

.x-form-field.x-trigger-noedit {
	width: 186px !important;
}
