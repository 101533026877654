﻿// Badge Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base badge styles

span.badge {
	background: #B3B3B3;
	border-radius: 2px;
	color: #FFFFFF;
	display: inline-block;
	font-size: 9px;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 8px;
	margin-left: 5px;
	padding: 5px;
	text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.2);
}