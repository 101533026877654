﻿// Filter Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base filter styles

#filters {
	height: 24px;
	overflow: hidden;
	position: relative;
	padding: 6px 12px 3px;
	font-weight: bold;

	form {
		display: inline-block;
		padding: 0;
		margin: 0;
		width: 100%;
    }
    
    .filters {
        font-weight: bold;
        overflow: hidden;
        height: 32px;
    }

	span {
		vertical-align: top;
		display: inline-block;
		padding: 4px 3px 0;
	}

	> form > input[type=text] {
		margin-right: 12px;
	}

	input {
		&[type=text] {
			box-shadow: none;
			margin: 2px;
			width: 120px;
			padding: 2px;
			background: rgba(255, 255, 255, 0.8);
			border: 0;
			font-weight: normal;
			font-size: 11px;
		}

		&.button {
			font-size: 12px;
			padding: 3px 8px;
			margin-right: 5px;
		}
	}

	span {
		float: left;
		color: #fff;
		font-size: 11px;
		font-weight: bold;
		margin-right: 2px;
	}

	ul li {
		display: block;
		float: left;

		a {
			color: #fff;
			border-left: 1px solid #ccc;
			display: block;
			float: left;
			padding: 0 10px;
			line-height: 12px;
		}

		&:first-child a {
			border: none;
		}
	}

	.more {
		float: right;
		text-indent: -9999px;
		width: 20px;
		height: 20px;
	}

	.x-form-field-trigger-wrap {
		input[type=text] {
			padding: 2px;
			line-height: 11px;
			height: 13px;
            border: $input-border;
            border-radius: 3px;
		}

		span.ui-icon {
			display: inline-block;
			position: absolute;
			top: 3px;
			right: 12px;
			width: 10px;
		}

		.x-form-trigger-over, .x-form-trigger-click {
			background-position: -64px -16px;
		}
	}

	input[type=text].date {
		background-clip: padding-box;
		border-radius: 3px;
		color: #333333;
		float: left;
		width: 80px;
	}

	span.buttons {
		float: right;
		padding: 0;
	}
}

.filter-slide {
	position: relative;
}