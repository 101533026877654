﻿// Base Titlebar Styles
// Included in every theme via the _base.scss file
//
// This partial sets up our base titlebar styles

.title-bar {
	height: 32px;
	position: relative;
	overflow: hidden;

	span.title {
		display: block;
		float: left;
		padding: 9px 12px;
	}

	.buttons {
		float: right;
		margin: 4px 10px 0 0;
	}
}

tr.title-bar td {
	border-left-width: 0 !important;
	border-right-width: 0 !important;

	&:first-child {
		border-left-width: 1px !important;
	}

	&:last-child {
		border-right-width: 1px !important;
	}
}